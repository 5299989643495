import React from 'react';

import { BtnBar, Checkbox, CtaBtn, DangerAlert, InfoAlert, WaitingAlert } from '@tmapy/style-guide';

import { DialogHeading } from '../../app/components/dialogheading';
import { QUERY_STATE } from '../../constants';

export class Permissions extends React.PureComponent<any, {}> {
  public render() {
    return (
      <div className='sg-a-p-4 sg-u-vs-4'>
        <div>
          <DialogHeading>Share with users</DialogHeading>
          <p className='sg-u-type-sml sg-u-c-body-darkest'>
            Users selected below and all Administrators will have access to the map
          </p>
        </div>
        {this.props.queryState === QUERY_STATE.WAITING && <WaitingAlert>Loading...</WaitingAlert>}
        {this.props.queryState === QUERY_STATE.FAIL && (
          <DangerAlert>The list of users is not currently available.</DangerAlert>
        )}
        {this.props.queryState === QUERY_STATE.SUCCESS && this.props.users.length < 1 && (
          <InfoAlert>No users found. Add a new user.</InfoAlert>
        )}

        <div>
          {this.props.queryState === QUERY_STATE.SUCCESS && this.props.users.length && (
            <ul>
              {this.props.users.map((item: any) => (
                <li key={item.id}>
                  <Checkbox
                    isChecked={!!item.isChecked}
                    isDisabled={this.props.isMapPublic}
                    onChange={(e) => this.props.onInputChange(e, item.id)}
                  >
                    {`${item.name} ${item.surname}`}
                  </Checkbox>
                </li>
              ))}
            </ul>
          )}
          <div className='sg-a-mt-2 sg-u-bt sg-a-pt-2'>
            <Checkbox
              isChecked={this.props.isMapPublic}
              onChange={this.props.onPublicMapFlagChange}
            >
              Anyone with the link
            </Checkbox>
            <div className={this.props.isMapPublic ? 'sg-a-v-v sg-a-mt-1' : 'sg-a-v-h sg-a-h-0'}>
              <InfoAlert>
                Anyone, who has the link can access the map and the data. No login required.
              </InfoAlert>
            </div>
          </div>
        </div>

        <BtnBar>
          <CtaBtn onClick={this.props.onSubmit}>Done</CtaBtn>
        </BtnBar>
      </div>
    );
  }
}
