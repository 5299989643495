export enum EDITOR_TYPE {
  NEW = 'NEW',
  EDIT = 'EDIT',
}

export enum UPLOAD_API {
  uploadTableData = '/map_data/',
  uploadAreasData = '/map_areas/',
  uploadGeoenrichmentData = '/map_area_geoenrichments/',
  uploadResourcesData = '/map_resources/',
}
