export const DELETE_MAP = 'DELETE_MAP';

export const GET_MAPS_SUCCESS = 'GET_MAPS_SUCCESS';
export const GET_MAPS_FAILURE = 'GET_MAPS_FAILURE';
export const GET_MAPS_WAITING = 'GET_MAPS_WAITING';

export enum DELETE_MAP_STATE {
  FAILURE = 'DELETE_MAP_STATE_FAILURE',
  EMPTY = 'DELETE_MAP_STATE_EMPTY',
  SUCCESS = 'DELETE_MAP_STATE_SUCCESS',
}
