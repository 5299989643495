import { AnyAction, Dispatch, Middleware } from 'redux';
import { AUTH_USER, LOGOUT_USER, SET_SELECTED_CUSTOMER } from './auth/constants';
import { authUtils } from './storageutils/auth';
import { storage } from './storageutils/storage';
import { LOCALSTORAGE_IDENT } from './constants';

/**
 * Middleware for history actions
 */
export const history: Middleware = () => (next: Dispatch) => (action: AnyAction) => {
  const result = next(action);

  switch (action.type) {
    case `GET_${AUTH_USER}_SUCCESS`:
      authUtils.saveAuthTokens(action.data);
      break;
    case SET_SELECTED_CUSTOMER:
      authUtils.saveSuperAdminSelectedCustomer(action.id);
      break;
    case LOGOUT_USER:
      storage.removeData(LOCALSTORAGE_IDENT.AUTH);
      break;
  }

  return result;
};
