import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import { UserList } from '../components/userlist';
import {
  changeFilterName,
  getUsers,
  setEditData,
  deleteUser,
  setDeleteQueryState,
} from '../actions';

import { getFilteredUser } from '../selectors/getuser';
import { AppState } from '../../rootreducer';
import { DELETE_USER_STATE, USER_FORM_TYPE } from '../constants';
import { QUERY_STATE } from '../../constants';

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;

export type IUserListProps = IStateProps & IDispatchProps & RouteComponentProps;

const mapStateToProps = (state: AppState) => ({
  users: getFilteredUser(state),
  filterName: state.userlist.filterName,
  queryState: state.userlist.queryState,
  editId: state.userlist.editId,
  isFormVisible: state.userlist.isFormVisible,
  userFormType: state.userlist.formType,
  deleteQueryState: state.userlist.deleteQueryState,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onFilterNameChange: (
    e: React.ChangeEvent | React.MouseEvent,
    eventData: { name: string; value: string },
  ) => {
    dispatch(changeFilterName(eventData.value));
  },
  onDeleteBtnClick: (e: React.SyntheticEvent, id: { data: number }) => {
    e.stopPropagation();
    dispatch(deleteUser(id.data));
  },
  onComponentMount: () => {
    dispatch(getUsers());
  },
  onComponentUnmount: () => {
    dispatch(setDeleteQueryState(DELETE_USER_STATE.EMPTY, QUERY_STATE.EMPTY));
  },
  onFormHideBtnClick: () => {
    dispatch(setEditData(NaN, USER_FORM_TYPE.NONE));
  },
  onEditBtnClick: (e: React.SyntheticEvent, id: { data: number }) => {
    dispatch(setEditData(id.data, USER_FORM_TYPE.EDIT));
  },
});

export const UserListContainer = withRouter(
  connect<IStateProps, IDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps,
    mapDispatchToProps,
  )(UserList),
);
