import React from 'react';
import { Redirect } from 'react-router';

import { callApi } from '../../utils/api';
import { QUERY_STATE } from '../../constants';

import { IMapProps } from './openmap';
import { Map } from '../components/map';

import { getConfigurationWithSaveUrl, loadConfigData } from '../../editor/utils/data';

export default interface IMapState {
  queryState: QUERY_STATE;
  needAuthentication: boolean;
}

export class MapContainer extends React.PureComponent<IMapProps, IMapState> {
  private config: any = null;
  private postMessageSource: Window | undefined;
  private isCorpisReady = false;

  constructor(props: IMapProps) {
    super(props);

    this.state = {
      queryState: QUERY_STATE.EMPTY,
      needAuthentication: false,
    };

    window.addEventListener('message', this.receiveMessage);
  }

  public componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  }

  public async componentDidMount() {
    this.setState({ queryState: QUERY_STATE.WAITING });
    /*
        - ocekava odezvu endpointu s:
            - validnim configuration
            - informaci ze je potreba se prihlasit (pokud jiz neni)
            - informaci ze na mapu prihlaseny uzivatel nema pravo
        */
    const response = await callApi({
      api: this.props.api,
      path: this.props.path,
      accessToken: this.props.accessToken,
      refreshToken: this.props.refreshToken,
      dispatch: this.props.dispatch,
    });

    if (response && response.configuration) {
      this.config = await loadConfigData(
        response.configuration,
        this.props.accessToken,
        this.props.api,
      );

      if (this.isCorpisReady && this.config) {
        this.config = getConfigurationWithSaveUrl(
          this.config,
          response.configuration.data.table.uploadTableData.source,
        );

        this.sendConfig();
        this.setState({ queryState: QUERY_STATE.SUCCESS });
      }
    } else {
      // zobrazeni chybove hlasky v pripade prihlaseni, ale neopravneni, nebo neexistujici mapy
      if (this.props.isAuthenticated) {
        this.setState({ queryState: QUERY_STATE.FAIL });
      }
      // presmerovani na login page
      else {
        this.setState({
          needAuthentication: true,
        });
      }
    }
  }

  /**
   * Send configuration to Corpis Maps over postMessage
   * https://codemirror.net/doc/manual.html#addons
   * inspiration https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
   */
  private async sendConfig() {
    const targetWin = this.postMessageSource || (window as any)['corpisClient'];
    targetWin.postMessage(
      {
        corpis: { config: this.config, refreshToken: this.props.refreshToken, api: this.props.api },
      },
      '*',
    );
  }

  /**
   * Listener for Window.postMessage()
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
   * @param e event
   */
  private receiveMessage = (e: any) => {
    if (e.data && e.data.corpis && e.data.corpis.ready) {
      this.isCorpisReady = true;
      this.postMessageSource = e.source;
      if (this.config) {
        this.sendConfig();
        this.setState({ queryState: QUERY_STATE.SUCCESS });
      }
    }
  };

  public render() {
    return this.state.needAuthentication ? (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: this.props.location },
        }}
      />
    ) : (
      <Map {...this.props} {...this.state} />
    );
  }
}
