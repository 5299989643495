import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { Login } from '../components/loginpage';
import { authUser } from '../actions';
import { AppState } from '../../rootreducer';

export type ILoginProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: AppState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  queryState: state.auth.queryState,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onSubmit: (email: string, password: string) => {
    dispatch(authUser(email, password));
  },
  onComponentUnmount: () => {},
});

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;

export const LoginContainer = connect<IStateProps, IDispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
