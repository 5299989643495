import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { UserFormContainer } from './userform';

import { USER_FORM_TITLE, USER_FORM_TYPE } from '../constants';
import { AppState } from '../../rootreducer';
import { successSubmit, setEditData } from '../actions';

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;

export type IEditUserProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: AppState) => ({
  api: state.app.config.api,
  path: `/users/${state.userlist.editId}/`,
  accessToken: state.auth.access,
  refreshToken: state.auth.refresh,
  token: state.auth.access,
  formType: state.userlist.formType,
  formTitle: USER_FORM_TITLE.EDIT,
  failText: 'Editing of the user details is not currently available. Please try again later.',
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onSuccessSubmit: () => {
    dispatch(successSubmit());
  },
  onClose: () => {
    dispatch(setEditData(NaN, USER_FORM_TYPE.NONE));
  },
  dispatch,
});

export const EditUserContainer = connect<IStateProps, IDispatchProps, any, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(UserFormContainer);
