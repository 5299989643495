import { App } from './app';
import * as serviceWorker from './serviceWorker';
import { initExternalAuth } from './externalAuth';

console.log('Version: ' + process.env.REACT_APP_VERSION);
interface AppConfig {
  api: string;
  baseName: string;
}

initExternalAuth();

export interface WindowExtended extends Window {
  APP_CONFIG: AppConfig;
}

if (!(window.CSS && window.CSS.supports)) {
  window.location.assign('legacy.html');
} else {
  (async () => {
    const config: AppConfig = (window as WindowExtended & typeof globalThis).APP_CONFIG || {};
    const app = new App(config);
    app.init(config.baseName);
  })();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
