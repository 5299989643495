import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Menu } from '../components/menu';
import { setEditData } from '../../users/actions';
import { USER_FORM_TYPE, ROLES } from '../../users/constants';
import { AppState } from '../../rootreducer';

export type IMenuProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: AppState) => ({
  isLoggedAdmin: state.auth.user.role === ROLES.ADMIN || state.auth.user.role === ROLES.SUPERADMIN,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onNewUserClick: () => {
    dispatch(setEditData(NaN, USER_FORM_TYPE.NEW));
  },
});

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const MenuContainer = withRouter(
  connect<IStateProps, IDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps,
    mapDispatchToProps,
  )(Menu),
);
