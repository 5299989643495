import { defaultState } from '../auth/reducer';
import { storage } from './storage';
import { LOCALSTORAGE_IDENT } from '../constants';
import { IAuthResponse } from '../auth/actions';

export const authUtils = {
  saveAuthTokens: ({
    access,
    refresh,
    isAuthenticated,
    user,
  }: IAuthResponse & { isAuthenticated: boolean }) => {
    storage.saveData(LOCALSTORAGE_IDENT.AUTH, {
      ...storage.getData(LOCALSTORAGE_IDENT.AUTH),
      access,
      refresh,
      isAuthenticated, // TODO check for token expiration ????
      user,
    });
  },

  saveSuperAdminSelectedCustomer: (id: string) => {
    storage.saveData(LOCALSTORAGE_IDENT.AUTH, {
      ...storage.getData(LOCALSTORAGE_IDENT.AUTH),
      selectedCustomer: id,
    });
  },

  getDataFromStorage() {
    return {
      ...defaultState,
      ...storage.getData(LOCALSTORAGE_IDENT.AUTH),
    };
  },
};
