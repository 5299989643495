import React from 'react';
import { Prompt, RouteComponentProps } from 'react-router-dom';

import {
  isTouchDevice,
  BtnBar,
  Checkbox,
  CtaBtn,
  CloseDialogBtn,
  Form,
  FormItem,
  FormRow,
  Input,
  SecondaryBtn,
  WaitingAlert,
  DangerAlert,
} from '@tmapy/style-guide';

import { DialogHeading } from '../../app/components/dialogheading';
import { USER_FORM_TYPE, ROLES } from '../constants';
import { QUERY_STATE } from '../../constants';
import { IUserFormProps } from '../containers/userform';

export class UserForm extends React.PureComponent<IUserFormProps & RouteComponentProps, {}> {
  public render() {
    return (
      <>
        <CloseDialogBtn onClick={this.props.onClose} />
        <div className='sg-a-p-4 sg-u-vs-4'>
          <div className='sg-a-d-f sg-a-jc-sb sg-a-ai-c'>
            <DialogHeading>{this.props.formTitle}</DialogHeading>
          </div>
          {this.props.data.loadQueryState === QUERY_STATE.WAITING && (
            <WaitingAlert>Please wait...</WaitingAlert>
          )}
          {this.props.data.loadQueryState === QUERY_STATE.FAIL && (
            <DangerAlert>
              User details are not currently available, please try again later.
            </DangerAlert>
          )}
          {(this.props.data.loadQueryState === QUERY_STATE.SUCCESS ||
            this.props.formType === USER_FORM_TYPE.NEW) && (
            <Form onSubmit={this.props.onSubmit}>
              <div className='sg-u-vs-2'>
                <FormRow>
                  <FormItem
                    label='First Name'
                    requiredLabel='required'
                    isRequired={this.props.formType !== USER_FORM_TYPE.PROFILE}
                    isInvalid={!this.props.data.formData.name.isValid}
                    errorMessage='Enter the first name'
                  >
                    <Input
                      name='name'
                      isRequired={this.props.formType !== USER_FORM_TYPE.PROFILE}
                      isDisabled={this.props.formType === USER_FORM_TYPE.PROFILE}
                      isInvalid={!this.props.data.formData.name.isValid}
                      autoFocus={this.props.formType === USER_FORM_TYPE.NEW && !isTouchDevice()}
                      autoComplete='firstname'
                      value={this.props.data.formData.name.value}
                      onChange={this.props.onItemChange}
                    />
                  </FormItem>
                  <FormItem
                    label='Last Name'
                    requiredLabel='required'
                    isRequired={this.props.formType !== USER_FORM_TYPE.PROFILE}
                    isInvalid={!this.props.data.formData.surname.isValid}
                    errorMessage='Enter the last name'
                  >
                    <Input
                      name='surname'
                      autoComplete='surname'
                      isRequired={this.props.formType !== USER_FORM_TYPE.PROFILE}
                      isDisabled={this.props.formType === USER_FORM_TYPE.PROFILE}
                      isInvalid={!this.props.data.formData.surname.isValid}
                      value={this.props.data.formData.surname.value}
                      onChange={this.props.onItemChange}
                    />
                  </FormItem>
                </FormRow>

                <FormRow>
                  <FormItem
                    label='Email (Login Name)'
                    requiredLabel='required'
                    isRequired={this.props.formType === USER_FORM_TYPE.NEW}
                    isInvalid={!this.props.data.formData.email.isValid}
                    errorMessage='Make sure your email address is correct'
                  >
                    <Input
                      type='email'
                      name='email'
                      isRequired={this.props.formType === USER_FORM_TYPE.NEW}
                      isInvalid={!this.props.data.formData.email.isValid}
                      autoComplete='email'
                      value={this.props.data.formData.email.value}
                      isDisabled={
                        this.props.formType === USER_FORM_TYPE.EDIT ||
                        this.props.formType === USER_FORM_TYPE.PROFILE
                      }
                      onChange={this.props.onItemChange}
                    />
                  </FormItem>
                </FormRow>

                {(this.props.formType === USER_FORM_TYPE.NEW ||
                  this.props.formType === USER_FORM_TYPE.PROFILE) && (
                  <>
                    <FormRow>
                      <FormItem
                        label={
                          this.props.formType === USER_FORM_TYPE.PROFILE
                            ? 'New Password'
                            : 'Password'
                        }
                        requiredLabel='required'
                        isRequired={this.props.formType !== USER_FORM_TYPE.PROFILE}
                        hint='Password must contain at least 8 characters'
                        isInvalid={!this.props.data.formData.password.isValid}
                        errorMessage='The password you entered doesn`t contain at least 8 characters'
                      >
                        <Input
                          name='password'
                          isRequired={this.props.formType !== USER_FORM_TYPE.PROFILE}
                          isInvalid={!this.props.data.formData.password.isValid}
                          type='password'
                          autoComplete='new-password'
                          value={this.props.data.formData.password.value}
                          onChange={this.props.onItemChange}
                        />
                      </FormItem>
                      <FormItem
                        label='Confirm Password'
                        requiredLabel='required'
                        isRequired={this.props.formType !== USER_FORM_TYPE.PROFILE}
                        hint='Please reenter your password'
                        isInvalid={!this.props.data.formData.newPassword.isValid}
                        errorMessage='The password you entered doesn`t match'
                      >
                        <Input
                          name='newPassword'
                          isRequired={this.props.formType !== USER_FORM_TYPE.PROFILE}
                          isInvalid={!this.props.data.formData.newPassword.isValid}
                          type='password'
                          autoComplete='new-password'
                          value={this.props.data.formData.newPassword.value}
                          onChange={this.props.onItemChange}
                        />
                      </FormItem>
                    </FormRow>
                  </>
                )}

                {this.props.formType !== USER_FORM_TYPE.PROFILE && (
                  <FormItem label=' '>
                    <Checkbox
                      value={'role'}
                      isDisabled={this.props.data.formData.role.value === ROLES.SUPERADMIN}
                      isChecked={
                        this.props.data.formData.role.value === ROLES.ADMIN ||
                        this.props.data.formData.role.value === ROLES.SUPERADMIN
                      }
                      name='role'
                      onChange={this.props.onRoleCheckboxChange}
                    >
                      Administrator
                    </Checkbox>
                  </FormItem>
                )}

                {this.props.data.submitQueryState === QUERY_STATE.WAITING && (
                  <WaitingAlert>Please wait...</WaitingAlert>
                )}
                {this.props.data.submitQueryState === QUERY_STATE.FAIL && (
                  <DangerAlert>{this.props.failText}</DangerAlert>
                )}
                <BtnBar>
                  <>
                    {this.props.formType === USER_FORM_TYPE.NEW && (
                      <SecondaryBtn isDanger onClick={this.props.onClear}>
                        Clear Form
                      </SecondaryBtn>
                    )}
                    <SecondaryBtn isDanger onClick={this.props.onClose}>
                      Cancel
                    </SecondaryBtn>
                    <CtaBtn
                      isDisabled={this.props.data.submitQueryState === QUERY_STATE.WAITING}
                      onClick={this.props.onSubmit}
                    >
                      {this.props.formType === USER_FORM_TYPE.EDIT ||
                      this.props.formType === USER_FORM_TYPE.PROFILE
                        ? 'Save'
                        : 'Add New User'}
                    </CtaBtn>
                  </>
                </BtnBar>
              </div>
            </Form>
          )}
          <Prompt
            when={
              this.props.data.isFormChanged &&
              this.props.data.submitQueryState !== QUERY_STATE.SUCCESS
            }
            message='The changes weren`t saved. Do you want to close the form?'
          />
        </div>
      </>
    );
  }
}
