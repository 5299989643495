import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import { MapContainer } from './map';
import { AppState } from '../../rootreducer';

export type IMapProps = IStateProps & {
  dispatch: ThunkDispatch<any, any, any>;
} & RouteComponentProps;

interface IParams {
  id?: string;
}

const mapStateToProps = (state: AppState, ownProps: RouteComponentProps<IParams>) => ({
  api: state.app.config.api,
  path: `/maps/${ownProps.match.params.id}/`,
  accessToken: state.auth.access,
  refreshToken: state.auth.refresh,
  isAuthenticated: state.auth.isAuthenticated,
  corpisClientSrc: state.app.config.corpisClientUrl,
});

type IStateProps = ReturnType<typeof mapStateToProps>;

export const OpenMapContainer = connect<IStateProps, null, RouteComponentProps, AppState>(
  mapStateToProps,
)(MapContainer);
