const enumColors = [
  'OrRd',
  'PuBu',
  'BuPu',
  'Oranges',
  'BuGn',
  'YlOrBr',
  'YlGn',
  'Reds',
  'RdPu',
  'Greens',
  'YlGnBu',
  'Purples',
  'GnBu',
  'Greys',
  'YlOrRd',
  'PuRd',
  'Blues',
  'PuBuGn',
  'Spectral',
  'RdYlGn',
  'RdBu',
  'PiYG',
  'PRGn',
  'RdYlBu',
  'BrBG',
  'RdGy',
  'PuOr',
  'Set2',
  'Accent',
  'Set1',
  'Set3',
  'Dark2',
  'Paired',
  'Pastel2',
  'Pastel1',
];
const enumMethod = ['ckmeans', 'custom', 'equalInterval', 'quantile', 'jenks'];

// tslint:disable:max-line-length
export const schema = {
  defaultUiSchema: {
    data: {
      metadata: {
        classNames: 'jdx-section-plainObject',
        'ui:order': ['label', 'field', 'resource', 'format', 'filter', 'info', 'search', '*'],
        filter: {
          classNames: 'jdx-section jdx-section-3rd',
        },
        search: {
          classNames: 'jdx-section jdx-section-3rd',
        },
        info: {
          classNames: 'jdx-section jdx-section-3rd',
        },
        series: {
          classNames: 'jdx-section jdx-section-3rd',
        },
        aggregation: {
          classNames: 'jdx-section jdx-section-3rd',
        },
        computed: {
          classNames: 'jdx-section jdx-section-3rd',
        },
      },
    },
  },
  version: {
    value: 4,
    formUI: {
      'ui:widget': 'hidden',
    },
    formSchema: {
      type: 'number',
    },
  },
  brand: {
    formUI: {
      classNames: 'jdx-section jdx-section-1st',
      appName: {
        classNames: 'jdx-section jdx-section-2nd',
      },
      partner: {
        classNames: 'jdx-section jdx-section-2nd',
      },
    },
    formSchema: {
      type: 'object',
      title: 'Brand',
      properties: {
        appName: {
          title: 'Application Name',
          type: 'object',
          properties: {
            title: {
              type: 'string',
              title: 'Title',
            },
            subtitle: {
              type: 'string',
              title: 'Subtitle',
            },
          },
        },
        partner: {
          title: 'Partner',
          type: 'object',
          properties: {
            name: {
              type: 'string',
              title: 'Brand Name',
            },
            logo: {
              type: 'string',
              title: 'Brand Logo',
              description:
                'Enter the URL address or the relative path (to the configuration file) of the logo. All common image file formats (PNG, JPEG, SVG, ..) are supported.',
            },
          },
        },
      },
    },
  },
  data: {
    config: {
      resources: 'resources',
    },
    formUI: {
      areas: {
        classNames: 'jdx-section jdx-section-2nd',
        uploadAreasData: {
          title: 'Areas Source',
          'ui:field': 'upload',
        },
        uploadGeoenrichmentData: {
          title: 'GeoEnrichment Source',
          'ui:field': 'upload',
        },
        items: {
          classNames: 'jdx-section jdx-section-array',
          uploadAreasData: {
            title: 'Areas Source',
            'ui:field': 'upload',
          },
          uploadGeoenrichmentData: {
            title: 'GeoEnrichment Source',
            'ui:field': 'upload',
          },
        },
      },
      classNames: 'jdx-section jdx-section-1st',
      table: {
        classNames: 'jdx-section jdx-section-2nd',
        uploadTableData: {
          'ui:field': 'upload',
        },
      },
      metadata: {
        'ui:order': [
          'label',
          'field',
          'resource',
          'format',
          '*',
          'search',
          'filter',
          'info',
          'chart',
          'series',
          'serieslabel',
          'aggregation',
          'computed',
        ],
        classNames: 'jdx-section jdx-section-2nd',
        items: {
          classNames: 'jdx-section jdx-section-array',
          'ui:order': [
            'label',
            'field',
            'resource',
            'format',
            '*',
            'search',
            'filter',
            'info',
            'chart',
            'series',
            'serieslabel',
            'aggregation',
            'computed',
          ],
          filter: {
            classNames: 'jdx-section jdx-section-3rd',
          },
          search: {
            classNames: 'jdx-section jdx-section-3rd',
          },
          info: {
            classNames: 'jdx-section jdx-section-3rd',
          },
          series: {
            classNames: 'jdx-section jdx-section-3rd',
          },
          aggregation: {
            classNames: 'jdx-section jdx-section-3rd',
          },
          computed: {
            classNames: 'jdx-section jdx-section-3rd',
          },
        },
      },
      resources: {
        classNames: 'jdx-section jdx-section-2nd',
        uploadResourcesData: {
          'ui:field': 'upload',
        },
        items: {
          classNames: 'jdx-section jdx-section-array',
          uploadResourcesData: {
            'ui:field': 'upload',
          },
          items: {
            items: {
              color: {
                'ui:widget': 'color',
              },
            },
          },
        },
      },
    },
    formSchema: {
      title: 'Data',
      type: 'object',
      description:
        'IMPORTANT - To display the data on the map, create at least one visualization in the Visualization section.',
      properties: {
        table: {
          type: 'object',
          title: 'Source',
          properties: {
            uploadTableData: {
              type: 'object',
              properties: {
                file: { type: 'string' },
              },
            },
            aliasFields: {
              type: 'array',
              title: 'Data Fields Aliases',
              description:
                'Rename a data field in the data source before it is used in Corpis Maps. E.g. rename "latitude" to "lat". (optional)',
              items: {
                type: 'object',
                properties: {
                  field: {
                    title: 'Field',
                    type: 'string',
                  },
                  alias: {
                    title: 'Alias',
                    type: 'string',
                  },
                },
              },
            },
          },
        },
        metadata: {
          type: 'array',
          title: 'Data Fields Metadata',
          items: {
            type: 'object',
            properties: {
              field: {
                title: 'Field ID',
                type: 'string',
              },
              label: {
                title: 'Label',
                type: 'string',
              },
              search: {
                title: 'Search',
                type: 'object',
                properties: {
                  enable: {
                    title: 'Search by this metadata item and show it in the search results',
                    type: 'boolean',
                  },
                },
              },
              filter: {
                title: 'Filter',
                type: 'object',
                properties: {
                  enable: {
                    title: 'Show in filters',
                    type: 'boolean',
                  },
                  order: {
                    title: 'Order',
                    description: 'The lowest number = filter at the top',
                    type: 'number',
                  },
                  expand: {
                    title: 'Expand',
                    description: 'Expanded by default',
                    type: 'boolean',
                  },
                },
              },
              info: {
                type: 'object',
                title: 'Info',
                description:
                  'Field can be displayed in the Info panel of the "Base" and/or the "Aggregation" type of visualizations.',
                properties: {
                  show: {
                    title: 'Show in the Info panel',
                    type: 'boolean',
                    default: true,
                  },
                  edit: {
                    title: 'Allow data editing',
                    type: 'boolean',
                    default: false,
                  },
                  base: {
                    type: 'boolean',
                    title: 'Base (simple, heatmap, cluster, values, breaks)',
                    default: true,
                  },
                  aggregation: {
                    type: 'boolean',
                    title: 'Aggregation (aggregation)',
                    default: true,
                  },
                },
              },
              resource: {
                type: 'string',
                title: 'Resource ID',
                description:
                  'If the field contains the codes of values, enter your own Resource list ID (e.g. list_segmentcode) here. The same Resource ID needs to be used in the "Data - Resources" section.', // todo set any other element ?
              },
              format: {
                type: 'string',
                title: 'Format',
                description: 'Choose field format',
                enum: ['text', 'multilinetext', 'number', 'date', 'datetime', 'email', 'url', 'array'],
              },
              chart: {
                title: 'Chart',
                type: 'object',
                description:
                  'Enter the minimum and maximum chart values if necessary (by default, the values are chosen automatically)',
                properties: {
                  min: {
                    title: 'Minimum Value',
                    type: 'number',
                  },
                  max: {
                    title: 'Maximum Value',
                    type: 'number',
                  },
                },
              },
              series: {
                type: 'array',
                title: 'Series',
                description:
                  'If you want to add a series (timeline, info chart) to your map, enter the X-axis labels here. If you are going to create a series from more fields, continue below and select "toSeries" in the Method picklist.',
                items: {
                  type: 'string',
                },
              },
              serieslabel: {
                type: 'string',
                title: 'Series Label (series title for aggregations)', // todo set only if series ??
              },
              aggregation: {
                type: 'array',
                title: 'Aggregation',
                description:
                  'Create aggregation fields that will appear in the Info panel of the aggregation (Visualization - Mode "aggregation" ) type of visualization in the map.',
                items: {
                  type: 'object',
                  properties: {
                    label: {
                      title: 'Label',
                      type: 'string',
                    },
                    function: {
                      type: 'string',
                      title: 'Function',
                      description: 'Select aggregation function',
                      enum: ['count', 'sum', 'avg', 'min', 'max'],
                    },
                  },
                },
              },
              computed: {
                type: 'object',
                title: 'Computed',
                description: 'Create a new field or a series from the data source fields.',
                properties: {
                  method: {
                    type: 'string',
                    title: 'Method',
                    description:
                      'Select the method. "Expression" enables to create a new field calculated from the data source fields. "toSeries" creates a series from data source fields.',
                    enum: ['expression', 'toSeries'],
                  },
                },
                dependencies: {
                  method: {
                    oneOf: [
                      {
                        properties: {
                          method: {
                            enum: ['expression'],
                          },
                          expression: {
                            type: 'string',
                            title: 'Expression',
                            description:
                              'Enter the expression, e.g. "round(sales / employees * 10) / 10"',
                          },
                        },
                      },
                      {
                        properties: {
                          method: {
                            enum: ['toSeries'],
                          },
                          fields: {
                            title: 'Fields',
                            type: 'array',
                            items: {
                              type: 'string',
                            },
                          },
                        },
                      },
                    ],
                  },
                },
              },
            },
            required: ['field'],
            dependencies: {
              format: {
                oneOf: [
                  {
                    properties: {
                      format: {
                        enum: ['text', 'multilinetext', 'number', 'date', 'datetime', 'email', 'array'],
                      },
                    },
                  },
                  {
                    properties: {
                      format: {
                        enum: ['url'],
                      },
                      alias: {
                        type: 'string',
                        title: 'Alias',
                        description: 'Enter the URL alias',
                      },
                      target: {
                        type: 'string',
                        title: 'Target',
                        description: 'Enter the URL target (default is _blank)',
                      },
                    },
                  },
                ],
              },
            },
          },
        },
        resources: {
          type: 'array',
          title: 'Resources',
          description:
            'Create resource lists for data fields with the "Resource ID" defined in Metadata.',
          items: {
            type: 'object',
            properties: {
              ident: {
                title: 'Resource ID',
                type: 'string',
              },
              uploadResourcesData: {
                type: 'object',
                properties: {
                  file: { type: 'string' },
                },
              },
              items: {
                title: 'Items',
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    key: {
                      title: 'Key (the code in the data source)',
                      type: 'string',
                    },
                    label: {
                      title: 'Label (the string value or the meaning of the code)',
                      type: 'string',
                    },
                    color: {
                      title: 'Color',
                      type: 'string',
                      description: 'Map symbol color',
                    },
                    radius: {
                      title: 'Radius',
                      type: 'number',
                      description: 'Map symbol size',
                    },
                  },
                  required: ['key', 'label'],
                },
              },
            },
            required: ['ident'],
          },
        },
        areas: {
          title: 'Areas',
          type: 'array',
          items: {
            type: 'object',
            properties: {
              label: {
                title: 'Label',
                type: 'string',
              },
              uploadAreasData: {
                type: 'object',
                properties: {
                  file: { type: 'string' },
                },
              },
              textMaxZoom: {
                title: 'Labels Maximum Zoom',
                type: 'number',
                description: 'Enter the maximum map zoom level (0 - 19) for map labels.',
              },
              textField: {
                title: 'Text Field',
                type: 'string',
                description:
                  'Enter the name of the field (one from the source file above) that will be used as a map label.',
              },
              uploadGeoenrichmentData: {
                type: 'object',
                properties: {
                  file: { type: 'string' },
                },
              },
              geoenrichmentAreaId: {
                title: 'GeoEnrichment Source ID',
                type: 'string',
                description:
                  'Enter a field name containing the ID values matching the given ID values of areas.',
              },
            },
            required: ['label'],
          },
        },
      },
    },
  },
  locale: {
    formUI: {
      classNames: 'jdx-section jdx-section-1st',
      general: {
        classNames: 'jdx-section jdx-section-2nd',
        zoom: {
          classNames: 'jdx-section jdx-section-3rd',
        },
      },
      data: {
        classNames: 'jdx-section jdx-section-2nd',
        visualizations: {
          classNames: 'jdx-section jdx-section-3rd',
        },
        aggregations: {
          classNames: 'jdx-section jdx-section-3rd',
        },
        filters: {
          classNames: 'jdx-section jdx-section-3rd',
        },
        series: {
          classNames: 'jdx-section jdx-section-3rd',
        },
      },
      info: {
        classNames: 'jdx-section jdx-section-2nd',
      },
      subjectList: {
        classNames: 'jdx-section jdx-section-2nd',
      },
      search: {
        classNames: 'jdx-section jdx-section-2nd',
      },
      chart: {
        classNames: 'jdx-section jdx-section-2nd',
      },
      message: {
        classNames: 'jdx-section jdx-section-2nd',
      },
      export: {
        classNames: 'jdx-section jdx-section-2nd',
      },
    },
    formSchema: {
      title: 'Locale',
      type: 'object',
      properties: {
        general: {
          type: 'object',
          title: 'Map',
          properties: {
            mapItemLabel: {
              type: 'string',
              title: 'Main Panel Icon Label',
              description: 'Map icon label (mobile version only)',
            },
            showInMapBtnLabel: {
              type: 'string',
              title: 'Show in Map',
              description:
                'Button that enables to display the Map view directly from the Info panel (mobile version only)',
            },
            zoom: {
              type: 'object',
              title: 'Zoom',
              properties: {
                inTooltip: {
                  type: 'string',
                  title: 'Zoon In Button',
                },
                outTooltip: {
                  type: 'string',
                  title: 'Zoom Out Button',
                },
              },
            },
          },
        },
        data: {
          type: 'object',
          title: 'Data',
          properties: {
            title: {
              type: 'string',
              title: 'Main Panel Icon Label',
            },
            visualizations: {
              type: 'object',
              title: 'Visualizations',
              properties: {
                title: {
                  type: 'string',
                  title: 'Options Panel Title',
                },
              },
            },
            aggregations: {
              type: 'object',
              title: 'Aggregations',
              properties: {
                title: {
                  type: 'string',
                  title: 'Options Panel Title',
                },
              },
            },
            filters: {
              type: 'object',
              title: 'Filters',
              properties: {
                title: {
                  type: 'string',
                  title: 'Options Panel Title',
                },
                clear: {
                  type: 'string',
                  title: 'Clear Filters Button',
                },
                show: {
                  type: 'string',
                  title: 'Expand All Button',
                },
                hide: {
                  type: 'string',
                  title: 'Collapse All Button',
                },
                from: {
                  type: 'string',
                  title: 'From (From-To Filter)',
                },
                to: {
                  type: 'string',
                  title: 'To (From-To Filter)',
                },
                spatialTitle: {
                  type: 'string',
                  title: 'Spatial Filter Title',
                },
                spatialLabel: {
                  type: 'string',
                  title: 'Spatial Filter Label',
                },
                spatialAlert: {
                  type: 'string',
                  title: 'Spatial Filter Alert',
                },
              },
            },
          },
        },
        info: {
          type: 'object',
          title: 'Info',
          properties: {
            title: {
              type: 'string',
              title: 'Info Panel Title',
            },
            submitBtnLabel: {
              type: 'string',
              title: 'Submit Button Label',
            },
            queryStateFailText: {
              type: 'string',
              title: 'Save Unsuccessful State',
            },
          },
        },
        featureList: {
          type: 'object',
          title: 'Subject List',
          properties: {
            title: {
              type: 'string',
              title: 'Subject List Panel Title ( clusters, ... )',
            },
          },
        },
        search: {
          type: 'object',
          title: 'Search',
          properties: {
            title: {
              type: 'string',
              title: 'Main Panel Title',
            },
            placeholder: {
              type: 'string',
              title: 'Placeholder (tooltip message)',
            },
            noResults: {
              type: 'string',
              title: 'No Results',
            },
            overLimit: {
              type: 'string',
              title: 'Large Number of Records Alert',
            },
          },
        },
        chart: {
          type: 'object',
          title: 'Chart',
          properties: {
            title: {
              type: 'string',
              title: 'Chart Panel Icon Title',
            },
          },
        },
        message: {
          type: 'object',
          title: 'Messages',
          properties: {
            loading: {
              type: 'string',
              title: 'Loading State',
            },
            fail: {
              type: 'string',
              title: 'Error State',
            },
            failConfig: {
              type: 'string',
              title: 'Error State of Config',
            },
            failVersion: {
              type: 'string',
              title: 'Error State of Version',
            },
          },
        },
        export: {
          type: 'object',
          title: 'Print',
          properties: {
            title: {
              type: 'string',
              title: 'Print Panel Title',
            },
            pageOption: {
              type: 'string',
              title: 'Page Options Title',
            },
            moduleOptions: {
              title: 'Module Options',
              type: 'object',
              properties: {
                previewPart: {
                  type: 'string',
                  title: 'Preview Part Button Title',
                },
                settingsPart: {
                  type: 'string',
                  title: 'Settings Part Button Title',
                },
              },
            },
            orientation: {
              title: 'Page Options',
              type: 'object',
              properties: {
                portrait: {
                  type: 'string',
                  title: 'Portrait',
                },
                landscape: {
                  type: 'string',
                  title: 'Landscape',
                },
              },
            },
            titleText: {
              type: 'string',
              title: 'Title',
            },
            subTitleText: {
              type: 'string',
              title: 'Subtitle',
            },
            legend: {
              type: 'string',
              title: 'Legend',
            },
            copyright: {
              type: 'string',
              title: 'Copyright',
            },
          },
        },
      },
    },
  },
  visualizations: {
    formUI: {
      classNames: 'jdx-section jdx-section-1st',
      color: {
        'ui:widget': 'color',
      },
      defaultResource: {
        color: {
          'ui:widget': 'color',
        },
      },
      items: {
        classNames: 'jdx-section jdx-section-array',
        color: {
          'ui:widget': 'color',
        },
        defaultResource: {
          color: {
            'ui:widget': 'color',
          },
        },
      },
    },
    formSchema: {
      type: 'array',
      title: 'Visualizations',
      description:
        'Create at least one visualization to display the data defined in the "Data - Table" section on the map. Choose one of the "non-aggregation" (Renderer) or "aggregation" (Mode) visualizations.',
      items: {
        type: 'object',
        required: ['label'],
        properties: {
          label: {
            type: 'string',
            title: 'Label',
          },
          renderer: {
            title: 'Renderer (non-aggregation)',
            description: 'Choose one of the non-aggregation visualizations',
            type: 'string',
            enum: ['simple', 'heatmap', 'cluster', 'values', 'breaks'],
          },
          mode: {
            title: 'Mode (aggregation)',
            description: 'Choose this option, if you want to create the aggregation visualization',
            type: 'string',
            enum: ['aggregation'],
          },
          method: {
            type: 'string',
            title: 'Method',
            description:
              'Choose the classification method. Select the "custom" value from the picklist to create custom breaks based on your specific needs.',
            enum: enumMethod,
          },
        },
        dependencies: {
          method: {
            oneOf: [
              {
                properties: {
                  method: {
                    enum: ['ckmeans', 'equalInterval', 'quantile', 'jenks'],
                  },
                },
              },
              {
                properties: {
                  method: {
                    enum: ['custom'],
                  },
                  breaks: {
                    type: 'array',
                    title: 'Breaks',
                    description: 'Create custom breaks based on your specific needs',
                    items: {
                      type: 'number',
                    },
                  },
                },
              },
            ],
          },
          mode: {
            oneOf: [
              {
                properties: {
                  mode: {
                    enum: ['aggregation'],
                  },
                  extraFields: {
                    type: 'array',
                    title: 'Extra Fields',
                    description: 'Create new data fields for the aggregation Info panel',
                    items: {
                      type: 'object',
                      properties: {
                        field: {
                          type: 'string',
                          title: 'Field',
                        },
                        label: {
                          type: 'string',
                          title: 'Label',
                        },
                        computed: {
                          type: 'object',
                          title: 'Computed',
                          description: 'Create a new field from the data source fields.',
                          properties: {
                            method: {
                              type: 'string',
                              title: 'Method',
                              description:
                                'Select the method. "Expression" enables to create a new field calculated from the data source fields.',
                              enum: ['expression'],
                            },
                            expression: {
                              type: 'string',
                              title: 'Expression',
                              description:
                                'Enter the expression, e.g. "round(sales / employees * 10) / 10"',
                            },
                          },
                        },
                      },
                    },
                  },
                  submodes: {
                    type: 'array',
                    title: 'Submodes',
                    minItems: 1,
                    items: {
                      type: 'object',
                      properties: {
                        label: {
                          type: 'string',
                          title: 'Title',
                          description:
                            'Submode title. Submode is a metric used for aggreation visualization.',
                        },
                        renderer: {
                          type: 'string',
                          title: 'Renderer',
                          description: 'Choose the renderer',
                          enum: ['breaks'],
                        },
                        field: {
                          type: 'string',
                          title: 'Field',
                          description:
                            'Enter the string created from two values "Field ID.Aggregation Function" (e.g. "sales.sum"). Field ID is listed for each data field in the "Data - Metadata" section, Aggregation Function needs to be created first in the "Aggregation" section of a data field in "Data - Metadata".',
                        },
                        method: {
                          type: 'string',
                          title: 'Method',
                          description:
                            'Choose the classification method. Select the "custom" value from the picklist to create custom breaks based on your specific needs.',
                          enum: enumMethod,
                        },
                        breaks: {
                          type: 'array',
                          title: 'Breaks',
                          description: 'Create custom breaks based on your specific needs',
                          items: {
                            type: 'number',
                          },
                        },
                        colors: {
                          type: 'string',
                          title: 'Colors',
                          description: 'Choose the map color scheme',
                          enum: enumColors,
                        },
                        count: {
                          title: 'Intervals',
                          minimum: 2,
                          maximum: 9,
                          type: 'number',
                          description:
                            'Enter the number of classification intervals (min = 2, max = 9)',
                        },
                      },
                    },
                    required: ['field'],
                  },
                },
              },
            ],
          },
          renderer: {
            oneOf: [
              {
                properties: {
                  renderer: {
                    enum: ['heatmap'],
                  },
                  radiusLabel: {
                    title: 'Radius',
                    type: 'string',
                    description: 'Radius of points',
                  },
                },
              },
              {
                properties: {
                  renderer: {
                    enum: ['cluster'],
                  },
                  maxClusterZoom: {
                    title: 'Max Zoom',
                    type: 'number',
                    description: 'Maximum zoom level for clusters',
                  },
                  color: {
                    title: 'Color',
                    type: 'string',
                    description: 'Cluster symbol color',
                  },
                  radius: {
                    title: 'Radius',
                    type: 'number',
                    description: 'Map symbol size',
                  },
                },
              },
              {
                properties: {
                  renderer: {
                    enum: ['simple'],
                  },
                  color: {
                    title: 'Color',
                    type: 'string',
                    description: 'Map symbol color',
                  },
                  radius: {
                    title: 'Radius',
                    type: 'number',
                    description: 'Map symbol size',
                  },
                },
              },
              {
                properties: {
                  renderer: {
                    enum: ['values'],
                  },
                  field: {
                    type: 'string',
                    title: 'Field',
                    description:
                      'Enter the "Field ID". "Field ID" is listed for each data field in the "Data - Metadata" section.',
                  },
                  defaultResource: {
                    type: 'object',
                    title: 'Default Symbol Parameters',
                    properties: {
                      color: {
                        title: 'Color',
                        type: 'string',
                        description: 'Map symbol color',
                      },
                      radius: {
                        title: 'Radius',
                        type: 'number',
                        description: 'Map symbol size',
                      },
                    },
                  },
                },
                required: ['field'],
              },
              {
                properties: {
                  renderer: {
                    enum: ['breaks'],
                  },
                  field: {
                    title: 'Field',
                    type: 'string',
                    description:
                      'Enter the "Field ID". "Field ID" is listed for each data field in the "Data - Metadata" section.',
                  },
                  radius: {
                    title: 'Radius',
                    type: 'array',
                    description:
                      'Enter the minimum and the maximum map symbol size (e.g. 4 and 12)',
                    minItems: 2,
                    items: {
                      type: 'number',
                    },
                  },
                  count: {
                    title: 'Intervals',
                    minimum: 2,
                    maximum: 9,
                    type: 'number',
                    description: 'Enter the number of classicication intervals (min = 2, max = 9)',
                  },
                  colors: {
                    type: 'string',
                    title: 'Colors',
                    description: 'Choose the map color scheme',
                    enum: enumColors,
                  },
                },
                required: ['field', 'radius'],
              },
            ],
          },
        },
      },
    },
  },
  map: {
    formUI: {
      classNames: 'jdx-section jdx-section-1st',
      'ui:order': ['zoom', 'center', '*'],
      baseLayers: {
        classNames: 'jdx-section jdx-section-2nd',
        source: {
          attribution: {
            items: {
              href: {
                'ui:widget': 'uri',
                'ui:placeholder': 'http://',
              },
            },
          },
        },
        opacity: {
          'ui:widget': 'range',
        },
        filter: {
          fillStyle: {
            'ui:widget': 'color',
          },
          alpha: {
            'ui:widget': 'range',
          },
        },
        style: {
          stroke: {
            color: {
              'ui:widget': 'color',
            },
          },
          fill: {
            color: {
              'ui:widget': 'color',
            },
          },
        },
        items: {
          classNames: 'jdx-section jdx-section-array',
          'ui:order': ['opacity', 'type', 'source', 'filter', '*'],
          source: {
            attribution: {
              items: {
                href: {
                  'ui:widget': 'uri',
                  'ui:placeholder': 'http://',
                },
              },
            },
          },
          opacity: {
            'ui:widget': 'range',
          },
          filter: {
            fillStyle: {
              'ui:widget': 'color',
            },
            alpha: {
              'ui:widget': 'range',
            },
          },
          style: {
            stroke: {
              color: {
                'ui:widget': 'color',
              },
            },
            fill: {
              color: {
                'ui:widget': 'color',
              },
            },
          },
        },
      },
      zoom: {
        classNames: 'jdx-section jdx-section-2nd',
      },
      center: {
        classNames: 'jdx-section jdx-section-2nd',
      },
    },
    formSchema: {
      title: 'Map',
      type: 'object',
      properties: {
        baseLayers: {
          type: 'array',
          title: 'Base Layers',
          items: {
            type: 'object',
            properties: {
              type: {
                title: 'Renderer',
                type: 'string',
                description: 'Choose the base map type',
                enum: ['Tile', 'Vector'],
              },
              opacity: {
                minimum: 0.1,
                maximum: 1,
                multipleOf: 0.1,
                default: 1,
                type: 'number',
                title: 'Opacity',
                description: 'Set the value from 0.1 (high transparency) to 1 (no transparency)',
              },
              filter: {
                title: 'Filter',
                type: 'object',
                description: 'Specify other visual base map parameters (optional)',
                properties: {
                  effect: {
                    type: 'string',
                    title: 'Effect',
                    enum: ['grayscale', 'invert'],
                  },
                  fillStyle: {
                    type: 'string',
                    title: 'Fill Color',
                  },
                  operation: {
                    type: 'string',
                    title: 'Operation',
                    enum: ['luminosity'],
                  },
                  alpha: {
                    minimum: 0.1,
                    maximum: 1,
                    multipleOf: 0.1,
                    default: 1,
                    type: 'number',
                    title: 'Alpha Transparency',
                    description:
                      'Set the value from 0.1 (high transparency) to 1 (no transparency)',
                  },
                },
              },
              source: {
                type: 'object',
                title: 'Source',
                properties: {
                  type: {
                    title: 'Type',
                    type: 'string',
                    description: 'Choose the base map source type',
                    enum: ['XYZ', 'Vector'],
                  },
                  options: {
                    title: 'Options',
                    type: 'object',
                    properties: {
                      url: {
                        type: 'string',
                        title: 'URL',
                        description:
                          'Enter the URL address or the relative path (to the configuration file) of the base layer.',
                      },
                      crossOrigin: {
                        type: 'string',
                        title: 'Cross Origin',
                        description:
                          'Choose the "crossOrigin" attribute for loaded images, see https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image for more details.',
                        enum: ['Anonymous'],
                      },
                    },
                    required: ['url'],
                  },
                  attribution: {
                    title: 'Copyright',
                    description: 'Enter the copyright notice (optional)',
                    type: 'array',
                    items: {
                      type: 'object',
                      properties: {
                        label: {
                          type: 'string',
                          title: 'Label',
                        },
                        href: {
                          type: 'string',
                          title: 'URL',
                          description:
                            'Enter the URL address or the relative path (to the configuration file) of the page. (optional)',
                        },
                      },
                      required: ['label'],
                    },
                  },
                },
                dependencies: {
                  type: {
                    oneOf: [
                      {
                        properties: {
                          type: {
                            enum: ['XYZ'],
                          },
                        },
                      },
                      {
                        properties: {
                          type: {
                            enum: ['Vector'],
                          },
                          options: {
                            type: 'object',
                            properties: {
                              format: {
                                title: 'Source Data Format',
                                type: 'string',
                                description: 'Choose the vector map source data format',
                                enum: ['geojson', 'topojson'],
                              },
                            },
                          },
                        },
                      },
                    ],
                  },
                },
              },
            },
            dependencies: {
              type: {
                oneOf: [
                  {
                    properties: {
                      type: {
                        enum: ['Tile'],
                      },
                    },
                  },
                  {
                    properties: {
                      type: {
                        enum: ['Vector'],
                      },
                      style: {
                        type: 'object',
                        title: 'Style',
                        description: 'Define the vector map style',
                        properties: {
                          stroke: {
                            title: 'Stroke',
                            type: 'object',
                            properties: {
                              color: {
                                title: 'Color',
                                type: 'string',
                              },
                              width: {
                                title: 'Width',
                                type: 'number',
                              },
                            },
                          },
                          fill: {
                            title: 'Fill',
                            type: 'object',
                            properties: {
                              color: {
                                title: 'Color',
                                type: 'string',
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
        },
        zoom: {
          title: 'Zoom',
          type: 'object',
          properties: {
            initial: {
              type: 'number',
              title: 'Initial Zoom',
              description: 'Enter the default map zoom level 0 - 19',
            },
            max: {
              type: 'number',
              title: 'Maximum Zoom',
              description: 'Enter the maximum map zoom level 0 - 19',
            },
            min: {
              type: 'number',
              title: 'Minimum Zoom',
              description: 'Enter the minimum map zoom level 0 - 19',
            },
          },
        },
        center: {
          type: 'array',
          title: 'Center Point',
          description: 'Enter the default map center point (longitude/latitude)',
          minItems: 2,
          maxItems: 2,
          items: {
            type: 'number',
          },
        },
      },
    },
  },
};
