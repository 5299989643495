import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, Store } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { RootReducer, AppState } from './rootreducer';
import { LoginContainer } from './auth/containers/login';
import { PrivateRouteContainer } from './privateroute';
import { MapListContainer } from './maps/containers/maplist';
import { NewMapContainer } from './maps/containers/newmap';
import { EditMapContainer } from './maps/containers/editmap';
import { OpenMapContainer } from './maps/containers/openmap';

import { ErrorPage } from './app/components/errorpage';
import { UserListContainer } from './users/containers/userlist';
import { setConfigToStore } from './app/actions';

import { authUtils } from './storageutils/auth';
import { history } from './middlewares';

import '@tmapy/style-guide/dist/css/style-guide.css';
import './app/styles/overrides.sass';
import './app/styles/reset.scss';

/**
 * due the JEDEX external widgets (UPLOAD) which need data from CP state and should dispatch callAPI tokens
 */
export const getCustomerData = () => {
  const state = store.getState();
  return {
    selectedCustomer: state.auth.selectedCustomer,
    auth: state.auth,
    dispatch: store.dispatch,
  };
};
let store: Store<AppState>;
export class App {
  constructor(config: any) {
    const middleware = [thunk, history];
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const initState = {
      auth: authUtils.getDataFromStorage(),
    };

    store = createStore(RootReducer, initState, composeEnhancers(applyMiddleware(...middleware)));

    store.dispatch(setConfigToStore(config));
  }

  public init(baseName: string | undefined) {
    ReactDOM.render(
      <Provider store={store}>
        <Router basename={baseName}>
          <Switch>
            <PrivateRouteContainer exact path='/' component={MapListContainer} />
            <Route path='/login' component={LoginContainer} />
            <PrivateRouteContainer path='/maps/new' component={NewMapContainer} />
            <PrivateRouteContainer path='/maps/edit/:id' component={EditMapContainer} />
            <Route path='/maps/:id' component={OpenMapContainer} />
            <PrivateRouteContainer path='/users/' component={UserListContainer} />
            <Route
              render={(props) => <ErrorPage {...props} reason={`This page doesn't exist`} />}
            />
          </Switch>
        </Router>
      </Provider>,
      document.getElementById('root'),
    );
  }
}
