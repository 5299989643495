import React from 'react';

import { DangerAlert, WaitingAlert } from '@tmapy/style-guide';

import './map.scss';
import '../../app/components/viewportpage.sass';

import { QUERY_STATE } from '../../constants';
import { IMapProps } from '../containers/openmap';
import IMapState from '../containers/map';

export class Map extends React.PureComponent<IMapProps & IMapState, {}> {
  public render() {
    return (
      <>
        <iframe
          className='cp-map'
          title='Corpis Maps Client'
          src={this.props.corpisClientSrc}
          name='corpisClient'
        />
        {this.props.queryState !== QUERY_STATE.SUCCESS && (
          <div className='cp-viewportPage'>
            {this.props.queryState === QUERY_STATE.WAITING && (
              <WaitingAlert>Loading...</WaitingAlert>
            )}
            {this.props.queryState === QUERY_STATE.FAIL && (
              <DangerAlert>The map is not currently available. Please try again later.</DangerAlert>
            )}
          </div>
        )}
      </>
    );
  }
}
