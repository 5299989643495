export const USERS_LOAD_SUCCESS = 'USERS_LOAD_SUCCESS';
export const USERS_LOAD_FAILURE = 'USERS_LOAD_FAILURE';
export const DELETE_USER = 'DELETE_USER';
export const USERS_FILTER_NAME_CHANGED = 'USERS_FILTER_NAME_CHANGED';
export const USERS_EDIT_DATA_CHANGED = 'USERS_EDIT_DATA_CHANGED';

export const ROLES = {
  SUPERADMIN: 1,
  ADMIN: 2,
  VIEWER: 3,
};

export enum USER_FORM_TYPE {
  PROFILE = 'PROFILE',
  EDIT = 'EDIT',
  NEW = 'NEW',
  NONE = 'NONE',
}

export enum USER_FORM_TITLE {
  EDIT = 'User Details',
  NEW = 'Add New User',
  PROFILE = 'User Profile',
}

export enum DELETE_USER_STATE {
  FAILURE = 'DELETE_USER_STATE_FAILURE',
  EMPTY = 'DELETE_USER_STATE_EMPTY',
  SUCCESS = 'DELETE_USER_STATE_SUCCESS',
}
