import React from 'react';

import { Jedex } from '@tmapy/jedex';

import {
  SvgUsers,
  SvgCaretLeft,
  BtnBar,
  CtaBtn,
  DangerAlert,
  Dialog,
  TertiaryBtn,
  RadioBtnGroup,
  SecondaryBtn,
  POSITION,
  WaitingAlert,
} from '@tmapy/style-guide';

import './editor.sass';
import '../../app/components/viewportpage.sass';

import { QUERY_STATE } from '../../constants';
import { PermissionsContainer } from '../containers/permissions';
import { IEditMapProps } from '../../maps/containers/editmap';

import IEditorState from '../containers/editor';

interface IEditorProps {
  fields: { [key: string]: Function };
  jedexRef: React.RefObject<Jedex>;
  onPublicMapFlagChange(): void;
  onPermissionSubmit(allowedUsers: number[]): void;
  onFormSwitcherClick(): void;
  onJedexChange(): void;
  onShareBtnClick(): void;
  onRefreshBtnClick(): void;
  onCancelBtnClick(): void;
  onSaveBtnClick(): void;
  onModeChange(e: React.ChangeEvent<HTMLInputElement>): void;
}

export class Editor extends React.PureComponent<IEditorProps & IEditorState & IEditMapProps, {}> {
  public render() {
    let className = '';
    className += this.props.showJedex ? '' : ' cp-editor-withoutForm';

    return (
      <>
        <div className={'cp-editor' + className}>
          <div className='cp-editor--topBar'>
            <div className='sg-a-d-f sg-a-ai-c sg-u-hsl-2'>
              <div
                className={`cp-editor--jedexSwitcher${
                  this.props.showJedex ? '' : ' cp-editor--jedexSwitcher-isShown'
                }`}
              >
                <TertiaryBtn
                  icon={{ element: <SvgCaretLeft /> }}
                  onClick={this.props.onFormSwitcherClick}
                />
              </div>
              {this.props.showJedex && (
                <RadioBtnGroup
                  value={this.props.activeMode}
                  items={this.props.jedexModes}
                  onChange={this.props.onModeChange}
                />
              )}
              <div className='sg-a-ml-a sg-a-mr-a'>
                {this.props.saveQueryState === QUERY_STATE.FAIL && (
                  <DangerAlert>
                    The map wasn't saved because of the subscription limit. Delete unused maps or
                    contact your Corpis Maps provider and upgrade your account.
                  </DangerAlert>
                )}
                {this.props.saveQueryState === QUERY_STATE.WAITING && (
                  <WaitingAlert>Saving...</WaitingAlert>
                )}
              </div>
              <BtnBar>
                <SecondaryBtn isDanger onClick={this.props.onCancelBtnClick}>
                  Cancel
                </SecondaryBtn>
                <SecondaryBtn
                  icon={{ element: <SvgUsers />, position: POSITION.LEFT }}
                  onClick={this.props.onShareBtnClick}
                >
                  Share
                </SecondaryBtn>
                <CtaBtn onClick={this.props.onSaveBtnClick}>Save &amp; Close</CtaBtn>
              </BtnBar>
            </div>

            {this.props.isPermissionDialogShown && (
              <Dialog>
                <PermissionsContainer
                  api={this.props.api}
                  accessToken={this.props.accessToken}
                  refreshToken={this.props.refreshToken}
                  dispatch={this.props.dispatch}
                  allowedUsers={this.props.allowedUsers}
                  isMapPublic={this.props.isMapPublic}
                  onPublicMapFlagChange={this.props.onPublicMapFlagChange}
                  onSubmit={this.props.onPermissionSubmit}
                  selectedCustomer={this.props.selectedCustomer}
                />
              </Dialog>
            )}
          </div>

          <div className='cp-editor--form'>
            {this.props.jedexConfig.jsonFile && (
              <Jedex
                inputMode={this.props.activeMode}
                ref={this.props.jedexRef}
                config={this.props.jedexConfig}
                onChange={this.props.onJedexChange}
                fields={this.props.fields}
              />
            )}
          </div>
          <div className='cp-editor--map'>
            <iframe
              className='sg-a-h-100%'
              title='Corpis Maps Client'
              src={this.props.corpisClientSrc}
              name='corpisClient'
            />
            <div
              className={`cp-editor--refreshBtn${
                this.props.showRefreshBtn ? ' cp-editor--refreshBtn-isShown' : ''
              }`}
            >
              <CtaBtn onClick={this.props.onRefreshBtnClick}>Refresh map to see changes</CtaBtn>
            </div>
            {this.props.queryState === QUERY_STATE.WAITING && (
              <div className='cp-editor--mapAlert'>
                <WaitingAlert>Loading...</WaitingAlert>
              </div>
            )}
          </div>
        </div>
        {!this.props.jedexConfig.jsonFile && (
          <div className='cp-viewportPage cp-viewportPage-withBackground'>
            <WaitingAlert>Loading...</WaitingAlert>
          </div>
        )}
        {this.props.queryState === QUERY_STATE.FAIL && (
          <div className='cp-viewportPage cp-viewportPage-withBackground'>
            <DangerAlert>The map is not currently available. Please try again later.</DangerAlert>
          </div>
        )}
      </>
    );
  }
}
