import { callApi } from '../../utils/api';
import { IData, IConfig, IArea, IResource } from '../types';
import { csvToJson } from '@tmapy/webhk-js-lib';

/**
 * Zjisti, zda v datech neni odkaz na dalsi data, pokud ne, tak konfiguraci dat hned odesle.
 * Pokud v definici tabulky nebo resources jsou URL, tak je nejprve nahraje a teprve potom odesle.
 * @param appData data urcena pro aplikaci - config
 * @param dispatch redux dispatch
 */
export const loadConfigData = async (config: IConfig, accessToken: string, api: string) => {
  const appData = JSON.parse(JSON.stringify(config))
  return new Promise((resolve) => {
    const configuration = appData.data,
      urls: { type: string; url: string; aliasFields?: any; ident?: string | number }[] = [];
    let count = 0;

    if (configuration) {
      // point data
      if (
        configuration.table &&
        (configuration.table.source ||
          (configuration.table.uploadTableData && configuration.table.uploadTableData.source))
      ) {
        urls.push({
          type: 'table',
          url:
            (configuration.table.uploadTableData && configuration.table.uploadTableData.source) ||
            configuration.table.source,
          aliasFields: configuration.table.aliasFields,
        });
      }
      // areas [] and geoenrichment (area) data
      configuration.areas &&
        configuration.areas.forEach((area: IArea, idx: number) => {
          (area.source || area.uploadAreasData.source) &&
            urls.push({
              type: 'areas',
              ident: idx,
              url: (area.uploadAreasData && area.uploadAreasData.source) || area.source,
            });

          if (
            (area.geoenrichmentSource ||
              (area.uploadGeoenrichmentData && area.uploadGeoenrichmentData.source)) &&
            area.geoenrichmentAreaId
          ) {
            urls.push({
              type: 'geoenrichment',
              ident: idx,
              url:
                (area.uploadGeoenrichmentData && area.uploadGeoenrichmentData.source) ||
                area.geoenrichmentSource,
            });
          }
        });

      // resources [] data
      configuration.resources &&
        configuration.resources.forEach((resource: IResource) => {
          !(resource.items && resource.items.length) &&
            (typeof resource.source === 'string' || resource.uploadResourcesData.source) &&
            urls.push({
              type: 'resources',
              ident: resource.ident,
              url:
                (resource.uploadResourcesData && resource.uploadResourcesData.source) ||
                resource.source,
            });
        });
    }

    urls.forEach(async (item) => {
      count++;
      // corpis-portal bude po uploadu vracet kompletni URL, a bude nasledne ocekavat i token uzivatele
      // token se neposila v pripade ze se jedna o request na server zakaznika
      const response = await callApi({
        api: '',
        path: item.url,
        accessToken: isExternalRequest(item.url, api) ? undefined : accessToken,
      });
      if (response) {
        const isCSVDataFormat = /\.csv$/i.test(item.url);
        // portal service json data transform
        const data = isCSVDataFormat
          ? csvToJson(response)
          : response.data
          ? JSON.parse(response.data)
          : response;
        const transformData = getTransformData(data, item.aliasFields);

        switch (item.type) {
          case 'table':
            appData.data[item.type as keyof IData] = transformData;
            break;
          case 'areas':
            typeof item.ident !== 'undefined' &&
              (appData.data.areas[item.ident as number].data = transformData);
            break;
          case 'geoenrichment':
            typeof item.ident !== 'undefined' &&
              (appData.data.areas[item.ident as number].geoenrichment = transformData);
            break;
          case 'resources':
            const resource = appData.data[item.type].find(
              (r: { ident: string }) => r.ident === item.ident,
            );
            resource && (resource.items = transformData);
            break;
        }
      }

      !--count && resolve(appData);
    });

    !urls.length && resolve(appData);
  });
};

/**
 * vrati data rozsirena o alias fields, pokud jsou nastavena
 * @param data pole dat
 * @param aliasFields pole aliasu
 */
export const getTransformData = (
  data: any[],
  aliasFields?: { field: string; alias: string }[],
): any[] => {
  if (!aliasFields || !aliasFields.length) return data;

  return data.reduce((currentData, item) => {
    const reduceFields: { [key: string]: boolean } = {};

    aliasFields.forEach((af) => {
      item.hasOwnProperty(af.field) && (item[af.alias] = item[af.field]);
      reduceFields[af.field] = true;
    });
    // remove alias orig fields from data
    if (Object.keys(reduceFields).length) {
      currentData.push(
        Object.keys(item).reduce((object, key) => {
          !reduceFields[key] && ((object as any)[key] = item[key]);
          return object;
        }, {}),
      );
    } else {
      currentData.push(item);
    }

    return currentData;
  }, []);
};

export const getIdOfSources = (data: IData) => {
  const tableData = data.table.uploadTableData;
  return {
    ...(data.table.uploadTableData.source && {
      data:
        tableData.inputValue === tableData.source
          ? undefined
          : getIdFromUrlSource(tableData.source),
    }),
    areas: getListOfIds(data.areas, 'uploadAreasData'),
    resources: getListOfIds(data.resources, 'uploadResourcesData'),
    geoenrichments: getListOfIds(data.areas, 'uploadGeoenrichmentData'),
  };
};

const getListOfIds = (list: IResource[] | IArea[] = [], key: string) => {
  const urls: string[] = [];

  list.forEach((item: any) => {
    const itemData = item[key];
    if (itemData && itemData.source) {
      const id =
        itemData.source === itemData.inputValue ? undefined : getIdFromUrlSource(itemData.source);
      id && urls.push(id);
    }
  });

  return urls.length > 0 ? urls : undefined;
};

// Ziskani id z url: https://corpis-portal-server.matej7.tmapserver.cz/map_data/f31f1c1c-298d-4e8f-915f-f54b5f9c4859/
// protoze na backend se nyni posilaji pouze id datoveho zdroje a ne url
const getIdFromUrlSource = (url: string) => {
  const pathname = url ? new URL(url).pathname : '';
  const match = pathname.match(/([^/]+)\/?$/);

  return match ? match[1] : undefined;
};

// Zjisti, zda-li se jedna o odkaz na externi data (data na serveru zakaznika)
// pokud url neobsahuje cestu API => External
// jinak se vzdy jedna o portal data
const isExternalRequest = (url: string, api: string): boolean => {
  return url.indexOf(api) === -1;
};

export const getConfigurationWithSaveUrl = (configuration: any, dataUrl: string) => {
  const saveDataUrl = `${dataUrl}records/`;
  return {
    ...configuration,
    saveDataUrl
  };
}