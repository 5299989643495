export const baseMap = {
  version: 4,
  map: {
    baseLayers: [
      {
        type: 'Tile',
        source: {
          type: 'XYZ',
          options: {
            url: '//services7-{a-d}.tmapserver.cz/tileserver/styles/tmw/{z}/{x}/{y}.png',
          },
        },
      },
    ],
    center: [15.48, 49.74],
  },
};
