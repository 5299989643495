export enum QUERY_STATE {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  EMPTY = 'EMPTY',
  WAITING = 'WAITING',
}

export const LOCALSTORAGE_IDENT = {
  AUTH: 'auth',
};

export const UI = {
  USER_DIALOG_WIDTH: '420px',
};
