export const bookmarks = {
  default: '',
  maxLevel: 3,
  data: [
    {
      id: 'brand',
      label: 'Brand',
      childs: [
        {
          id: 'brand.appName',
          label: 'Application Name',
        },
        {
          id: 'brand.partner',
          label: 'Partner',
        },
      ],
    },
    {
      id: 'map',
      label: 'Map',
      childs: [
        {
          id: 'map.baseLayers',
          label: 'Base Layers',
          autoChilds: {
            label: 'type',
          },
        },
        {
          id: 'map.zoom',
          label: 'Zoom',
        },
        {
          id: 'map.center',
          label: 'Center Point',
        },
      ],
    },
    {
      id: 'data',
      label: 'Data',
      childs: [
        {
          id: 'data.table',
          label: 'Source',
        },
        {
          id: 'data.metadata',
          label: 'Metadata',
          autoChilds: {
            label: 'label',
          },
        },
        {
          id: 'data.areas',
          label: 'Areas',
          autoChilds: {
            label: 'label',
          },
        },
        {
          id: 'data.resources',
          label: 'Resources',
          autoChilds: {
            label: 'ident',
          },
        },
      ],
    },
    {
      id: 'visualizations',
      label: 'Visualization',
      autoChilds: {
        label: 'label',
      },
    },
    {
      id: 'locale',
      label: 'Locale',
      childs: [
        {
          id: 'locale.general',
          label: 'Map',
        },
        {
          id: 'locale.data',
          label: 'Data',
        },
        {
          id: 'locale.info',
          label: 'Info',
        },
        {
          id: 'locale.featureList',
          label: 'Subject List',
        },
        {
          id: 'locale.search',
          label: 'Search',
        },
        {
          id: 'locale.chart',
          label: 'Chart',
        },
        {
          id: 'locale.message',
          label: 'Messages',
        },
        {
          id: 'locale.export',
          label: 'Print',
        },
      ],
    },
  ],
};
