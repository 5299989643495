import { AppState } from '../../rootreducer';
import { filterByFText } from '@tmapy/webhk-js-lib';
import { createSelector } from 'reselect';
import { IUserDataList } from '../interfaces';

export const getFilteredUser = createSelector(
  [(state: AppState) => state.userlist.filterName, (state: AppState) => state.userlist.usersList],
  (searchText: string, users: IUserDataList[]) => {
    return searchText && users
      ? (filterByFText(searchText, users, 'fullText', 'fullText') as IUserDataList[])
      : users;
  },
);
