import { ROLES, USER_FORM_TYPE } from '../constants';

export const defaultData = {
  id: { value: NaN },
  name: { value: '', isValid: true }, // nesmi byt prazdne
  surname: { value: '', isValid: true }, // nesmi byt prazdne
  role: { value: ROLES.VIEWER },
  email: { value: '', isValid: true }, // nesmi byt prazdne a musi byt validni email
  password: { value: '', isValid: true },
  newPassword: { value: '', isValid: true },
};
export type IUserData = typeof defaultData;

export interface IFormItem {
  [key: string]: {
    value: string | number;
    isValid?: boolean;
  };
}

export type IUserDataKey = keyof {
  id: string;
  name: string;
  surname: string;
  role: string;
  email: string;
  password: string;
  newPassword: string;
};

// transforamce dat pro sluzbu POST or PUT!
export const prepareSubmittedData = (data: IUserData, formType: USER_FORM_TYPE) => {
  const transformedData: { [key: string]: string | number } = {};

  // neni potreba odesilat tyto polozky
  let excludeItems = ['id'];
  if (formType === USER_FORM_TYPE.EDIT) {
    excludeItems = [...excludeItems, 'password', 'newPassword'];
  }
  Object.keys(data).forEach((key) => {
    excludeItems.indexOf(key) < 0 &&
      data[key as IUserDataKey].value &&
      (transformedData[key] = data[key as IUserDataKey].value);
  });

  return transformedData;
};
