import React from 'react';

import {
  SvgTrash,
  isTouchDevice,
  DangerAlert,
  Dialog,
  FormItem,
  FormRow,
  InfoAlert,
  InfoBadge,
  Input,
  TertiaryBtn,
  SecondaryBtn,
  WaitingAlert,
} from '@tmapy/style-guide';

import { LayoutContainer } from '../../app/containers/layout';

import { QUERY_STATE, UI } from '../../constants';
import { IUserListProps } from '../containers/userlist';
import { EditUserContainer } from '../containers/edituser';
import { NewUserContainer } from '../containers/newuser';
import { USER_FORM_TYPE, ROLES } from '../constants';

export class UserList extends React.PureComponent<IUserListProps, {}> {
  public componentDidMount() {
    this.props.onComponentMount();
  }

  public componentWillUnmount() {
    this.props.onComponentUnmount();
  }

  public render() {
    const isFiltered = this.props.filterName;

    return (
      <LayoutContainer>
        <>
          <div className='sg-u-vs-4 sg-a-p-1 sg-a-mt-2'>
            {this.props.queryState === QUERY_STATE.WAITING && (
              <WaitingAlert>List of users is being loading...</WaitingAlert>
            )}
            {this.props.queryState === QUERY_STATE.FAIL && (
              <DangerAlert>The list of users is not currently available.</DangerAlert>
            )}
            {this.props.queryState === QUERY_STATE.SUCCESS &&
              this.props.users.length < 1 &&
              !isFiltered && <InfoAlert>No users found. Add a new user.</InfoAlert>}
            {this.props.deleteQueryState === QUERY_STATE.FAIL && (
              <DangerAlert>
                The user wasn't deleted. Make sure you have the Admin rights to delete users.
              </DangerAlert>
            )}

            {((this.props.queryState === QUERY_STATE.SUCCESS && this.props.users.length > 0) ||
              isFiltered) && (
              <>
                <FormRow>
                  <FormItem label='Search' isVertical>
                    <Input
                      clearBtnTooltip='Clear'
                      value={this.props.filterName}
                      autoFocus={!isTouchDevice()}
                      onChange={this.props.onFilterNameChange}
                    />
                  </FormItem>
                </FormRow>
                {this.props.users.length > 0 ? (
                  <ul className='sg-a-mt-1/2'>
                    {this.props.users.map((item) => (
                      <li className='sg-u-bb' key={item.id}>
                        <article
                          className='sg-a-d-f sg-a-ai-b sg-a-p-1'
                          onClick={(e) => this.props.onEditBtnClick(e, { data: item.id })}
                        >
                          <div className='sg-a-mr-2'>
                            <span>
                              {item.name} {item.surname}
                            </span>
                          </div>
                          <div className='sg-a-ml-a sg-a-d-f sg-a-ai-c sg-u-hsl-1'>
                            {(item.role === ROLES.ADMIN || item.role === ROLES.SUPERADMIN) && (
                              <div className='sg-a-mr-2'>
                                <InfoBadge>Administrator</InfoBadge>
                              </div>
                            )}
                            <SecondaryBtn data={item.id} onClick={this.props.onEditBtnClick}>
                              Edit
                            </SecondaryBtn>
                            <TertiaryBtn
                              data={item.id}
                              tooltip='Delete'
                              icon={{ element: <SvgTrash /> }}
                              isDanger
                              onClick={this.props.onDeleteBtnClick}
                            />
                          </div>
                        </article>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <InfoAlert>No matching search results.</InfoAlert>
                )}
              </>
            )}
          </div>
          {this.props.isFormVisible && (
            <Dialog width={UI.USER_DIALOG_WIDTH} onEscKeyDown={this.props.onFormHideBtnClick}>
              <>
                {this.props.userFormType === USER_FORM_TYPE.NEW && <NewUserContainer />}
                {this.props.userFormType === USER_FORM_TYPE.EDIT && <EditUserContainer />}
              </>
            </Dialog>
          )}
        </>
      </LayoutContainer>
    );
  }
}
