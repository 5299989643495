import { MapsActionTypes, IMaps } from './actions';
import * as con from './constants';
import { QUERY_STATE } from '../constants';

export type MapsState = {
  list: IMaps;
  queryState: QUERY_STATE;
  deleteQueryState: QUERY_STATE;
};

const defaultState: MapsState = {
  list: [] as IMaps,
  queryState: QUERY_STATE.EMPTY,
  deleteQueryState: QUERY_STATE.EMPTY,
};

export const maps = (state = defaultState, action: MapsActionTypes): MapsState => {
  switch (action.type) {
    case con.DELETE_MAP:
      return {
        ...state,
        list: state.list.filter((item) => item.id !== action.id),
      };
    case con.GET_MAPS_SUCCESS:
      return {
        ...state,
        ...{
          queryState: QUERY_STATE.SUCCESS,
          list: action.data,
        },
      };
    case con.GET_MAPS_FAILURE:
      return {
        ...state,
        ...{
          list: [],
          queryState: QUERY_STATE.FAIL,
        },
      };
    case con.GET_MAPS_WAITING:
      return {
        ...state,
        ...{
          queryState: QUERY_STATE.WAITING,
        },
      };
    case con.DELETE_MAP_STATE.FAILURE:
    case con.DELETE_MAP_STATE.EMPTY:
    case con.DELETE_MAP_STATE.SUCCESS:
      return {
        ...state,
        ...{
          deleteQueryState: action.queryState,
        },
      };
    default:
      return state;
  }
};
