import { Dispatch } from 'redux';

import { QUERY_STATE } from '../constants';
import * as con from './constants';
import { GetState } from '../types';
import { callApi } from '../utils/api';

// eslint-disable-next-line
export type MapsActionTypes =
  | ReturnType<typeof deleteMapFromState>
  | ReturnType<typeof mapsLoadSuccess>
  | ReturnType<typeof mapsLoadFail>
  | ReturnType<typeof mapsLoadWaiting>
  | ReturnType<typeof setDeleteQueryState>;

export interface IMapData {
  id: string | number;
  name: string;
  data: string;
  configuration: any;
  customer: string;
  creator: string;
  allowedUsers: string[];
  isDownloadable: boolean;
}

export type IMaps = IMapData[];

export const deleteMapFromState = (id: IMapData['id']) => ({
  type: con.DELETE_MAP as typeof con.DELETE_MAP,
  id,
});

export const setDeleteQueryState = (type: con.DELETE_MAP_STATE, queryState: QUERY_STATE) => ({
  type,
  queryState,
});

export const deleteMap = (id: IMapData['id']) => async (dispatch: Dispatch, getState: GetState) => {
  if (window.confirm('Do you really want to delete map?')) {
    const state = getState();

    dispatch(deleteMapFromState(id));

    const respond = await callApi({
      api: state.app.config.api,
      path: `/maps/${id}`,
      accessToken: state.auth.access,
      refreshToken: state.auth.refresh,
      method: 'DELETE',
      dispatch,
    });

    if (respond) {
      dispatch(setDeleteQueryState(con.DELETE_MAP_STATE.SUCCESS, QUERY_STATE.SUCCESS));
    } else {
      dispatch(setDeleteQueryState(con.DELETE_MAP_STATE.FAILURE, QUERY_STATE.FAIL));
    }
  }
};

export const mapsLoadSuccess = (data: IMaps) => ({
  type: con.GET_MAPS_SUCCESS as typeof con.GET_MAPS_SUCCESS,
  data,
});

export const mapsLoadFail = () => ({
  type: con.GET_MAPS_FAILURE as typeof con.GET_MAPS_FAILURE,
});

export const mapsLoadWaiting = () => ({
  type: con.GET_MAPS_WAITING as typeof con.GET_MAPS_WAITING,
});

export const getMaps = () => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();

  const selectedCustomer = state.auth.selectedCustomer;
  let path = '/maps/';
  selectedCustomer && (path += `?customer=${selectedCustomer}`);

  dispatch(mapsLoadWaiting());

  const respond = await callApi({
    api: state.app.config.api,
    path,
    accessToken: state.auth.access,
    refreshToken: state.auth.refresh,
    dispatch,
  });

  if (respond) {
    dispatch(mapsLoadSuccess(respond.results));
  } else {
    dispatch(mapsLoadFail());
  }
};
