import { SvgDownload, SvgTrash, TertiaryBtn, TertiaryLink, SecondaryBtn } from '@tmapy/style-guide';

import { useDownloadLink } from '../../hooks';
import { IMapItemProps } from '../containers/mapitem';

export const MapItem: React.FC<IMapItemProps> = (props) => {
  const downloadLink = useDownloadLink(`map_data/${props.data}`);

  const handleOpenBtnClick = () => {
    props.history.push(`/maps/${props.id}`);
  };

  const handleEditBtnClick = () => {
    props.history.push(`/maps/edit/${props.id}`);
  };

  const handleDeleteBtnClick = () => {
    props.onDeleteBtnClick(props.id);
  };

  return (
    <>
      <td>{props.name}</td>
      <td className='sg-a-p-1 sg-a-w-0'>
        <SecondaryBtn onClick={handleOpenBtnClick}>Open</SecondaryBtn>
      </td>
      {props.hasAdminAccess && (
        <>
          <td className='sg-a-p-1 sg-a-w-0'>
            <SecondaryBtn onClick={handleEditBtnClick}>Edit</SecondaryBtn>
          </td>
          <td className='sg-a-p-1 sg-a-w-0'>
            {props.isDownloadable && (
              <TertiaryLink
                {...downloadLink}
                target='_blank'
                tooltip='Download data'
                icon={{ element: <SvgDownload /> }}
              />
            )}
          </td>
          <td className='sg-a-p-1 sg-a-w-0'>
            <TertiaryBtn
              tooltip='Delete'
              icon={{ element: <SvgTrash /> }}
              isDanger
              onClick={handleDeleteBtnClick}
            />
          </td>
        </>
      )}
    </>
  );
};
