import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { MapItem } from '../components/mapitem';
import { AppState } from '../../rootreducer';
import { deleteMap, IMapData } from '../actions';
import { ROLES } from '../../users/constants';

type IMapItemOwnProps = IMapData & RouteComponentProps;

export type IMapItemProps = IStateProps & IMapItemOwnProps & IDispatchProps;

const mapStateToProps = (state: AppState) => ({
  hasAdminAccess: state.auth.user.role === ROLES.ADMIN || state.auth.user.role === ROLES.SUPERADMIN,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onDeleteBtnClick: (id: IMapData['id']) => dispatch(deleteMap(id)),
});

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;

export const MapItemContainer = withRouter(
  connect<IStateProps, IDispatchProps, IMapItemOwnProps, AppState>(
    mapStateToProps,
    mapDispatchToProps,
  )(MapItem),
);
