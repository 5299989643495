import React from 'react';
import { Dispatch } from 'redux';

import { callApi } from '../../utils/api';
import { QUERY_STATE } from '../../constants';
import { Permissions } from '../components/permissions';
import { ROLES } from '../../users/constants';

interface IUserItem {
  customer: string;
  email: string;
  id: number;
  name: string;
  password: string;
  role: number;
  surname: string;
  url: string;
  isChecked?: boolean;
}

interface IPermissionsContainerProps {
  api: string;
  accessToken: string;
  refreshToken: string;
  dispatch: Dispatch;
  allowedUsers: number[];
  isMapPublic: boolean;
  selectedCustomer: string;
  onPublicMapFlagChange(): void;
  onSubmit(users: number[]): void;
}

export class PermissionsContainer extends React.PureComponent<IPermissionsContainerProps, {}> {
  public readonly state = {
    queryState: QUERY_STATE.EMPTY,
    users: [] as IUserItem[],
  };

  public async componentDidMount() {
    this.setState({ queryState: QUERY_STATE.WAITING });

    let path = `/users/?role=${ROLES.VIEWER}`;
    this.props.selectedCustomer && (path += `&customer=${this.props.selectedCustomer}`);

    const response = await callApi({
      api: this.props.api,
      path,
      accessToken: this.props.accessToken,
      refreshToken: this.props.refreshToken,
      dispatch: this.props.dispatch,
    });

    if (response) {
      this.setState({
        users: (response.results as IUserItem[]).map((item) => {
          item.isChecked = this.props.allowedUsers.includes(item.id);
          return item;
        }),
      });
    }

    this.setState({
      queryState: response ? QUERY_STATE.SUCCESS : QUERY_STATE.FAIL,
    });
  }

  private handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    const editedUsers = this.state.users.map((item) => {
      if (item.id === id) {
        item.isChecked = e.currentTarget.checked;
      }

      return item;
    });

    this.setState({
      users: editedUsers,
    });
  };

  private handleSubmit = () => {
    this.props.onSubmit(this.state.users.filter((item) => item.isChecked).map((item) => item.id));
  };

  public render() {
    return (
      <Permissions
        {...this.state}
        isMapPublic={this.props.isMapPublic}
        onPublicMapFlagChange={this.props.onPublicMapFlagChange}
        onSubmit={this.handleSubmit}
        onInputChange={this.handleInputChange}
      />
    );
  }
}
