import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import {
  isTouchDevice,
  DangerAlert,
  Form,
  FormItem,
  Input,
  Link,
  LINK_SEVERITY,
  SubmitBtn,
  WaitingAlert,
  FormRow,
} from '@tmapy/style-guide';

import { ILoginProps } from '../containers/login';
import { QUERY_STATE } from '../../constants';

import './loginpage.sass';

export class Login extends React.PureComponent<RouteComponentProps & ILoginProps, {}> {
  public readonly state = {
    email: process.env.NODE_ENV === 'development' ? 'lube@tmapy.cz' : '',
    password: process.env.NODE_ENV === 'development' ? 'tmapy' : '',
    remember: false,
  };

  private handleEmailChange = (
    e: React.ChangeEvent | React.MouseEvent,
    eventData: { name: string; value: string },
  ) => {
    this.setState({
      email: eventData.value,
    });
  };

  private handlePasswordChange = (
    e: React.ChangeEvent | React.MouseEvent,
    eventData: { name: string; value: string },
  ) => {
    this.setState({
      password: eventData.value,
    });
  };

  private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.onSubmit(this.state.email, this.state.password);
  };

  public componentWillUnmount() {
    this.props.onComponentUnmount();
  }

  public render() {
    const { isAuthenticated, queryState } = this.props;
    const { from } = (this.props.location.state as any) || { from: { pathname: '/' } };

    if (isAuthenticated) return <Redirect to={from} />;

    return (
      <div className='cp-loginPage'>
        <div className='cp-loginPage--inner'>
          <Form onSubmit={this.handleSubmit}>
            <div className='sg-a-d-f sg-a-fd-c sg-u-vs-2 sg-a-p-4 sg-u-br'>
              <FormRow>
                <FormItem label='Email' isVertical>
                  <Input
                    type='text'
                    value={this.state.email}
                    autoFocus={!isTouchDevice()}
                    autoComplete='username'
                    hasClearBtn={false}
                    onChange={this.handleEmailChange}
                  />
                </FormItem>
              </FormRow>
              <FormRow>
                <FormItem label='Password' isVertical>
                  <Input
                    type='password'
                    value={this.state.password}
                    autoComplete='current-password'
                    hasClearBtn={false}
                    onChange={this.handlePasswordChange}
                  />
                </FormItem>
              </FormRow>
              <SubmitBtn>Login</SubmitBtn>
              {queryState.type === QUERY_STATE.FAIL && (
                <DangerAlert>Incorrect email or password.</DangerAlert>
              )}
              {queryState.type === QUERY_STATE.WAITING && (
                <WaitingAlert>Logging in...</WaitingAlert>
              )}
            </div>
          </Form>
          <section className='sg-a-p-4'>
            <h1 className='cp-loginPage--title'>Corpis Maps</h1>
            <p className='cp-loginPage--desc sg-a-mt-2 sg-u-type-med'>
              Corpis Maps is the extremely secure configurable analytical map platform for advanced
              spatial analysis of business data.{' '}
              <Link href='https://www.corpismaps.com/' severity={LINK_SEVERITY.COLORFUL}>
                See more.
              </Link>
            </p>
          </section>
        </div>
      </div>
    );
  }
}
