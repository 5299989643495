import { QUERY_STATE } from '../constants';
import { AUTH_USER, LOGOUT_USER, SET_SELECTED_CUSTOMER } from './constants';

export const defaultState = {
  isAuthenticated: false,
  user: {
    id: NaN,
    name: '',
    surname: '',
    role: NaN,
    email: '',
    customer: '',
    customersList: [],
  },
  refresh: '',
  access: '',
  queryState: {
    type: QUERY_STATE.EMPTY,
    error: undefined,
  },
  selectedCustomer: '',
};

export const auth = (state = defaultState, action: any): typeof defaultState => {
  switch (action.type) {
    case `GET_${AUTH_USER}_SUCCESS`:
      return {
        ...state,
        ...action.data,
        queryState: {
          type: QUERY_STATE.SUCCESS,
        },
      };
    case `GET_${AUTH_USER}_WAITING`:
      return {
        ...state,
        queryState: {
          ...state.queryState,
          type: QUERY_STATE.WAITING,
        },
      };
    case `GET_${AUTH_USER}_FAIL`:
      return {
        ...state,
        queryState: {
          type: QUERY_STATE.FAIL,
          error: action.error,
        },
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: {
          id: NaN,
          name: '',
          surname: '',
          role: NaN,
          email: '',
          customer: '',
          customersList: [],
        },
        isAuthenticated: false,
        refresh: '',
        access: '',
        queryState: {
          type: QUERY_STATE.EMPTY,
          error: undefined,
        },
      };
    case SET_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.id,
      };
    default:
      return state;
  }
};
