import React from 'react';

import './useravatar.sass';

export enum USER_AVATAR_THEME {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

interface IUserAvatarProps {
  theme: USER_AVATAR_THEME;
  children: string;
}

export class UserAvatar extends React.PureComponent<IUserAvatarProps, {}> {
  public render() {
    const className =
      this.props.theme === USER_AVATAR_THEME.DARK ? ' cp-userAvatar-dark' : ' cp-userAvatar-light';

    return <div className={'cp-userAvatar' + className}>{this.props.children}</div>;
  }
}
