import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { PageTitle, Select } from '@tmapy/style-guide';

import { UserInfoContainer } from '../containers/userinfo';
import { ILayoutContainerProps } from '../containers/layout';
import { MenuContainer } from '../containers/menu';

import './Layout.scss';

interface ILayoutProps {
  children: JSX.Element | JSX.Element[];
}

export class Layout extends React.PureComponent<
  ILayoutContainerProps & ILayoutProps & RouteComponentProps,
  {}
> {
  private handleCustomerChange = (e: React.BaseSyntheticEvent) => {
    this.props.onCustomerChange(e.target.value);
  };

  public render() {
    return (
      <>
        <header className='cp-layout--header'>
          <div className='cp-layout--block sg-a-d-f sg-a-ai-c sg-u-hsl-2'>
            <a className='cp-layout--title sg-a-mr-a' href='/'>
              <img src='logo.svg' alt='Corpis Maps logo' />
              <PageTitle company={this.props.customer}>Corpis Maps</PageTitle>
            </a>
            {this.props.isSuperAdmin && (
              <Select
                value={this.props.selectedCustomer}
                items={this.props.customersList}
                onChange={this.handleCustomerChange}
              />
            )}
            <UserInfoContainer />
          </div>
        </header>
        <main>
          <div className='cp-layout--block'>
            <MenuContainer />
            {this.props.children}
          </div>
        </main>
      </>
    );
  }
}
