import { combineReducers } from 'redux';

import { app } from './app/reducer';
import { auth } from './auth/reducer';
import { maps } from './maps/reducer';
import { userlist } from './users/reducer';

export const RootReducer = combineReducers({
  app,
  auth,
  maps,
  userlist,
});

export type AppState = ReturnType<typeof RootReducer>;
