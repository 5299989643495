import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Layout } from '../components/layout';
import { AppState } from '../../rootreducer';
import { setCustomer } from '../../auth/actions';
import { ROLES } from '../../users/constants';

import { getMaps } from '../../maps/actions';
import { getUsers } from '../../users/actions';
import { getCustomerLabel } from '../selectors/getcustomer';

export type ILayoutContainerProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: AppState) => ({
  customer: getCustomerLabel(state),
  selectedCustomer: state.auth.selectedCustomer,
  customersList: state.auth.user.customersList,
  isSuperAdmin: state.auth.user.role === ROLES.SUPERADMIN,
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>,
  ownProps: RouteComponentProps,
) => ({
  onCustomerChange: (value: string) => {
    dispatch(setCustomer(value));
    ownProps.location.pathname === '/' && dispatch(getMaps());
    ownProps.location.pathname === '/users/' && dispatch(getUsers());
  },
});

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;

export const LayoutContainer = withRouter(
  connect<IStateProps, IDispatchProps, RouteComponentProps, AppState>(
    mapStateToProps,
    mapDispatchToProps,
  )(Layout),
);
