import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { UserFormContainer } from './userform';

import { USER_FORM_TITLE, USER_FORM_TYPE } from '../constants';
import { AppState } from '../../rootreducer';
import { successSubmit, setEditData } from '../actions';

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;

export type INewUserProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: AppState) => ({
  api: state.app.config.api,
  path: '/users/',
  accessToken: state.auth.access,
  refreshToken: state.auth.refresh,
  formType: state.userlist.formType,
  formTitle: USER_FORM_TITLE.NEW,
  failText:
    "The user wasn't created, because of an existing user with the same email address (check the list of users) or because of the subscription limit (delete some users or contact your Corpis Maps provider and upgrade your account).",
  selectedCustomer: state.auth.selectedCustomer,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onSuccessSubmit: () => {
    dispatch(successSubmit());
  },
  onClose: () => {
    dispatch(setEditData(NaN, USER_FORM_TYPE.NONE));
  },
  dispatch,
});

export const NewUserContainer = connect<IStateProps, IDispatchProps, any, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(UserFormContainer);
