const domainAuthTokens: Record<string, string> = {};
const TOKEN_SEARCH_PREFIX = 'auth.';

export const initExternalAuth = () => {
  const location = window.location;
  const urlSearchParams = new URLSearchParams(location.search);
  const newSearchParams = new URLSearchParams();
  let hasParams = false;
  for (const [key, value] of urlSearchParams) {
    if (key.startsWith(TOKEN_SEARCH_PREFIX)) {
      domainAuthTokens[key.slice(TOKEN_SEARCH_PREFIX.length)] = value;
    } else {
      newSearchParams.append(key, value);
      hasParams = true;
    }
  }

  const url = `${location.pathname}${hasParams ? `?${newSearchParams}` : ''}`;
  window.history.replaceState(window.history.state, '', url);
};

export const getExternalAuthorization = (path: string): string | undefined => {
  const url = new URL(path);
  return domainAuthTokens[url.host];
};
