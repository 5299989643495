import { ThunkDispatch } from 'redux-thunk';
import { IConfig, GetState } from '../types';

export const SET_CONFIG_TO_STORE = 'SET_CONFIG_TO_STORE';
export const TOGGLE_PROFILE = 'TOGGLE_PROFILE';
export const SET_CUSTOMER = 'SET_CUSTOMER';

// eslint-disable-next-line
export type AppActionTypes = ReturnType<typeof setConfigToStore> | ReturnType<typeof hideProfile>;

export const setConfigToStore = (config: IConfig) => ({
  type: SET_CONFIG_TO_STORE as typeof SET_CONFIG_TO_STORE,
  config,
});

export const showProfile = () => (dispatch: ThunkDispatch<any, any, any>, getState: GetState) => {
  dispatch({ type: TOGGLE_PROFILE as typeof TOGGLE_PROFILE, id: getState().auth.user.id });
};

export const hideProfile = () => ({
  type: TOGGLE_PROFILE as typeof TOGGLE_PROFILE,
  id: NaN,
});
