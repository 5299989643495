import { ThunkDispatch } from 'redux-thunk';
import { callApi } from '../utils/api';
import { GetState } from '../types';
import * as con from './constants';
import { QUERY_STATE } from '../constants';
import { IUserDataList } from './interfaces';

export type UserActionTypes =
  | ReturnType<typeof usersLoadSuccess>
  | ReturnType<typeof usersLoadFail>
  | ReturnType<typeof changeFilterName>
  | ReturnType<typeof setEditData>
  | ReturnType<typeof deleteUserFromState>
  | ReturnType<typeof setDeleteQueryState>;

export const usersLoadSuccess = (data: IUserDataList[]) => ({
  type: con.USERS_LOAD_SUCCESS as typeof con.USERS_LOAD_SUCCESS,
  data,
});

export const usersLoadFail = () => ({
  type: con.USERS_LOAD_FAILURE as typeof con.USERS_LOAD_FAILURE,
});

export const changeFilterName = (value: string) => ({
  type: con.USERS_FILTER_NAME_CHANGED as typeof con.USERS_FILTER_NAME_CHANGED,
  value,
});

export const getUsers =
  () => async (dispatch: ThunkDispatch<any, any, any>, getState: GetState) => {
    const state = getState();

    const selectedCustomer = state.auth.selectedCustomer;
    let path = '/users/';
    selectedCustomer && (path += `?customer=${selectedCustomer}`);

    const respond = await callApi({
      api: state.app.config.api,
      path,
      accessToken: state.auth.access,
      refreshToken: state.auth.refresh,
      dispatch,
    });

    if (respond) {
      dispatch(usersLoadSuccess(respond.results));
    } else {
      dispatch(usersLoadFail());
    }
  };

export const setEditData = (id: number, formType: con.USER_FORM_TYPE) => ({
  type: con.USERS_EDIT_DATA_CHANGED as typeof con.USERS_EDIT_DATA_CHANGED,
  id,
  formType,
});

export const setDeleteQueryState = (type: con.DELETE_USER_STATE, queryState: QUERY_STATE) => ({
  type,
  queryState,
});

export const successSubmit = () => (dispatch: ThunkDispatch<any, any, any>) => {
  // set state
  dispatch(setEditData(NaN, con.USER_FORM_TYPE.NONE));
  // refresh data
  dispatch(getUsers());
};

export const deleteUser =
  (id: number) => async (dispatch: ThunkDispatch<any, any, any>, getState: GetState) => {
    if (window.confirm('Opravdu si přejete smazat uživatele?')) {
      const state = getState();

      dispatch(deleteUserFromState(id));

      const respond = await callApi({
        api: state.app.config.api,
        path: `/users/${id}`,
        accessToken: state.auth.access,
        refreshToken: state.auth.refresh,
        method: 'DELETE',
        dispatch,
      });

      if (respond) {
        dispatch(setDeleteQueryState(con.DELETE_USER_STATE.SUCCESS, QUERY_STATE.SUCCESS));
        dispatch(getUsers());
      } else {
        dispatch(setDeleteQueryState(con.DELETE_USER_STATE.FAILURE, QUERY_STATE.FAIL));
      }
    }
  };

export const deleteUserFromState = (id: number) => ({
  type: con.DELETE_USER as typeof con.DELETE_USER,
  id,
});
