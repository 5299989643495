import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import { EditorContainer } from '../../editor/containers/editor';
import { AppState } from '../../rootreducer';
import { EDITOR_TYPE } from '../../editor/constants';

export type INewMapProps = IStateProps & {
  dispatch: ThunkDispatch<any, any, any>;
} & RouteComponentProps;

const mapStateToProps = (state: AppState) => ({
  api: state.app.config.api,
  path: '/maps/',
  accessToken: state.auth.access,
  refreshToken: state.auth.refresh,
  type: EDITOR_TYPE.NEW,
  isAuthenticated: state.auth.isAuthenticated,
  selectedCustomer: state.auth.selectedCustomer,
  corpisClientSrc: state.app.config.corpisClientUrl,
});

type IStateProps = ReturnType<typeof mapStateToProps>;

export const NewMapContainer = connect<IStateProps, null, RouteComponentProps, AppState>(
  mapStateToProps,
)(EditorContainer);
