import React from 'react';

interface IDialogHeadingProps {
  children: string;
}

export class DialogHeading extends React.PureComponent<IDialogHeadingProps, {}> {
  public render() {
    return <h2 className='sg-u-type-med sg-u-type-bold'>{this.props.children}</h2>;
  }
}
