import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { callApi } from './utils/api';

export const useDownloadLink = (url: string) => {
  const api = useSelector((state: any) => state.app.config.api);
  const accessToken = useSelector((state: any) => state.auth.access);

  return useMemo(() => createDownloadLink(url, accessToken, api), [url, accessToken, api]);
}

const createDownloadLink = (url: string, accessToken: string, api: string) => {  
  return {
    href: `${url}/`,
    onClick: async (event: React.SyntheticEvent) => {
      event.preventDefault();
      const path = `${url}/create_download_token/`;

      try {
        const response = await callApi({
          api,
          path,
          accessToken,
          method: 'POST',
        });
        if (!response.token) {
          throw new Error('Cannot acquire token');
        }
        window.location.href = `${api}/${url}/download/?token=${response.token}`;
      } catch (error) {
        console.error('Error downloading file', error);
      }
    },
  };
}