import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { RouteComponentProps } from 'react-router-dom';
import { UserForm } from '../components/userform';
import { USER_FORM_TYPE, USER_FORM_TITLE, ROLES } from '../constants';
import { QUERY_STATE } from '../../constants';
import { getItemValidity, getValidation } from '../utils/validation';
import { prepareSubmittedData } from '../utils/data';
import { defaultData, IFormItem } from '../utils/data';
import { callApi } from '../../utils/api';

export interface IUserFormProps {
  api: string;
  path: string;
  accessToken?: string;
  refreshToken?: string;
  formType: USER_FORM_TYPE;
  formTitle: USER_FORM_TITLE;
  failText: string;
  data: IOriginalState;
  dispatch: ThunkDispatch<any, any, any>;
  selectedCustomer: string;
  onSuccessSubmit(): void;
  onRoleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onSubmit(): void;
  onItemChange(
    e: React.ChangeEvent | React.MouseEvent,
    eventData: { name: string; value: string },
  ): void;
  onClear(): void;
  onClose(): void;
}

interface IOriginalState {
  formData: any;
  submitQueryState: string;
  loadQueryState: string;
  isFormChanged: boolean;
}

export class UserFormContainer extends React.Component<IUserFormProps & RouteComponentProps, any> {
  public readonly state = {
    formData: { ...defaultData },
    submitQueryState: QUERY_STATE.EMPTY,
    loadQueryState: QUERY_STATE.EMPTY,
    isFormChanged: false,
  };

  private originalState: IOriginalState = {} as IOriginalState;

  public componentDidMount() {
    if (
      this.props.formType === USER_FORM_TYPE.EDIT ||
      this.props.formType === USER_FORM_TYPE.PROFILE
    ) {
      this.getDetail();
    }
  }

  public getDetail = async () => {
    this.setState({ loadQueryState: QUERY_STATE.WAITING });

    const respond = await callApi({
      api: this.props.api,
      path: this.props.path,
      accessToken: this.props.accessToken,
      refreshToken: this.props.refreshToken,
      method: 'GET',
      dispatch: this.props.dispatch,
    });

    if (respond) {
      this.setState({ loadQueryState: QUERY_STATE.SUCCESS });

      Object.keys(respond).forEach((key) => {
        if (respond[key] && key !== 'password') {
          this.setState((state: IOriginalState) => ({
            formData: {
              ...state.formData,
              [key]: {
                ...state.formData[key],
                value: respond[key],
              },
            },
          }));

          // store defaults after load data
          this.originalState = { ...this.state };
        }
      });
    } else {
      this.setState({ loadQueryState: QUERY_STATE.FAIL });
    }
  };

  public submitForm = async () => {
    this.setState({ submitQueryState: QUERY_STATE.WAITING });

    let body = prepareSubmittedData(this.state.formData, this.props.formType);
    if (this.props.formType === USER_FORM_TYPE.NEW && this.props.selectedCustomer) {
      body = { ...body, customer: this.props.selectedCustomer };
    }

    const respond = await callApi({
      api: this.props.api,
      path: this.props.path,
      accessToken: this.props.accessToken,
      refreshToken: this.props.refreshToken,
      method: this.props.formType === USER_FORM_TYPE.NEW ? 'POST' : 'PATCH',
      body,
      dispatch: this.props.dispatch,
    });

    if (respond) {
      this.setState({ submitQueryState: QUERY_STATE.SUCCESS });
      this.props.onSuccessSubmit();
    } else {
      this.setState({ submitQueryState: QUERY_STATE.FAIL });
    }
  };

  public handleSubmit = () => {
    if (this.validateForm()) {
      this.submitForm();
    }
  };

  public handleClear = () => {
    this.setState({
      formData: { ...defaultData },
      submitQueryState: QUERY_STATE.EMPTY,
      isFormChanged: false,
    });
  };

  public handleClose = () => this.props.onClose();

  public onRoleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked ? ROLES.ADMIN : ROLES.VIEWER;
    this.setItemDataState(event.target.name, value);
  };

  public onItemChange = (
    e: React.ChangeEvent | React.MouseEvent,
    eventData: { name: string; value: string },
  ) => {
    this.setItemDataState(eventData.name, eventData.value);
  };

  private setItemDataState(ident: string, value: string | number) {
    this.setState({
      formData: {
        ...this.state.formData,
        [ident]: {
          value,
          isValid: !(this.state.formData as IFormItem)[ident].isValid
            ? getItemValidity(ident, value)
            : true,
        },
      },
      isFormChanged: true,
      submitQueryState: QUERY_STATE.EMPTY,
    });
  }

  private validateForm() {
    const validation = getValidation(this.state.formData, this.props.formType);
    this.setState({
      formData: {
        ...this.state.formData,
        ...validation.validatedFields,
      },
    });

    return validation.isFormValid;
  }

  public render() {
    return (
      <UserForm
        {...this.props}
        data={this.state}
        onItemChange={this.onItemChange}
        onRoleCheckboxChange={this.onRoleCheckboxChange}
        onSubmit={this.handleSubmit}
        onClear={this.handleClear}
        onClose={this.handleClose}
      />
    );
  }
}
