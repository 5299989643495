import { ThunkDispatch } from 'redux-thunk';
import { LOGOUT_USER, AUTH_USER } from '../auth/constants';
import { getExternalAuthorization } from '../externalAuth';

type ICallApiData = {
  api: string;
  path: string;
  accessToken?: string;
  refreshToken?: string;
  method?: string;
  rawBody?: any;
  body?: {};
  dispatch?: ThunkDispatch<any, any, any>;
  signal?: AbortSignal | null;
};

export const getValidUrl = (api: string | undefined, path: string) => {
  if (!api) return path ? new URL(path).href : '';
  return new URL(path, api).href;
};

export const callApi = async (data: ICallApiData) => {
  const url = getValidUrl(data.api, data.path);

  const headers = new Headers();
  headers.append('Accept', 'application/json');

  // header only for config, not upload tables
  if (!data.rawBody) {
    headers.append('Content-Type', 'application/json');
  }

  if (data.accessToken) {
    headers.append('Authorization', `JWT ${data.accessToken}`);
  } else {
    const externalAuthorization = getExternalAuthorization(url);
    if (externalAuthorization) {
      headers.append('Authorization', externalAuthorization);
    }
  }

  let result = await fetch(url, {
    method: data.method || 'GET',
    signal: data.signal,
    ...(data.body && { body: JSON.stringify(data.body) }),
    ...(data.rawBody && { body: data.rawBody }),
    headers,
  })
    .then((response: Response) => {
      if (response.status >= 400) {
        if (data.dispatch && data.refreshToken && response.status === 401) {
          // token_not_valid
          return response.json();
        } else {
          console.error('Bad response from server', response.statusText);
          return null;
        }
      }
      if (response.status === 204) {
        // DELETE - No Content
        return 'ok';
      }
      return response.url.toLowerCase().indexOf('.csv') > -1 ? response.text() : response.json();
    })
    .catch((err) => {
      err.name !== 'AbortError' && console.error('Api error:', err);
      return null; // return type of error or throw Error
    });

  if (data.dispatch && data.refreshToken && result && result.code === 'token_not_valid') {
    const newToken = await callApi({
      api: data.api,
      path: '/auth/jwt/refresh',
      method: 'POST',
      body: { refresh: `${data.refreshToken}` },
      signal: data.signal,
    });

    if (newToken) {
      // update access token and recall API
      data.dispatch({
        type: `GET_${AUTH_USER}_SUCCESS`,
        data: newToken,
      });
      result = callApi({
        api: data.api,
        path: data.path,
        accessToken: newToken.access,
        method: data.method,
        body: data.body,
        signal: data.signal,
      });
    } else {
      // refresh token error -> logout
      data.dispatch({
        type: LOGOUT_USER,
      });
      result = null;
    }
  }

  return result;
};
