import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { DangerAlert, WaitingAlert, InfoAlert, Table } from '@tmapy/style-guide';

import { MapItemContainer } from '../containers/mapitem';
import { IMapListProps } from '../containers/maplist';

import { LayoutContainer } from '../../app/containers/layout';
import { QUERY_STATE } from '../../constants';

export class MapList extends React.PureComponent<IMapListProps & RouteComponentProps, {}> {
  public componentDidMount() {
    this.props.onComponentMount();
  }

  public componentWillUnmount() {
    this.props.onComponentUnmount();
  }

  public render() {
    return (
      <LayoutContainer>
        <div className='sg-u-vs-4 sg-a-p-1 sg-a-mt-2'>
          {this.props.deleteQueryState === QUERY_STATE.FAIL && (
            <DangerAlert>The map wasn't deleted. Please try again later.</DangerAlert>
          )}
          {this.props.queryState === QUERY_STATE.FAIL && (
            <DangerAlert>The list of maps is not currently available.</DangerAlert>
          )}
          {this.props.queryState === QUERY_STATE.WAITING && <WaitingAlert>Loading...</WaitingAlert>}
          {this.props.queryState === QUERY_STATE.SUCCESS && this.props.maps.length === 0 && (
            <InfoAlert>No maps found. Create your first analytical map.</InfoAlert>
          )}
          {this.props.queryState === QUERY_STATE.SUCCESS && this.props.maps.length > 0 && (
            <Table>
              {this.props.maps.map((item: any) => (
                <tr className='sg-u-bb' key={item.id}>
                  <MapItemContainer {...item} />
                </tr>
              ))}
            </Table>
          )}
        </div>
      </LayoutContainer>
    );
  }
}
