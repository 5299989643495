import { SET_CONFIG_TO_STORE, AppActionTypes, TOGGLE_PROFILE } from './actions';

const defaultState = {
  config: {
    api: '',
    baseName: '',
    corpisClientUrl: '',
  },
  profile: {
    id: NaN,
    isVisible: false,
  },
};

export const app = (state = defaultState, action: AppActionTypes): typeof defaultState => {
  switch (action.type) {
    case SET_CONFIG_TO_STORE:
      return {
        ...state,
        config: { ...action.config },
      };
    case TOGGLE_PROFILE:
      return {
        ...state,
        profile: {
          id: action.id,
          isVisible: !state.profile.isVisible,
        },
      };
    default:
      return state;
  }
};
