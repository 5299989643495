import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { SecondaryBtn } from '@tmapy/style-guide';

import { ILogoutProps } from '../containers/logout';

export class LogoutBtn extends React.PureComponent<ILogoutProps & RouteComponentProps, {}> {
  private handleClick = (e: React.FormEvent<HTMLFormElement>) => {
    this.props.onLogoutBtnClick();
    this.props.history.push('/login');
  };

  public render() {
    return this.props.isAuthenticated ? (
      <SecondaryBtn isDanger onClick={this.handleClick}>
        Logout
      </SecondaryBtn>
    ) : null;
  }
}
