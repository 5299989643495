import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { CtaBtn } from '@tmapy/style-guide';

import './errorpage.sass';

interface IErrorPageProps {
  reason: string;
  history: RouteComponentProps['history'];
}

export class ErrorPage extends React.PureComponent<IErrorPageProps, {}> {
  private handleClick = () => {
    this.props.history.push('/');
  };

  public render() {
    return (
      <div className='cp-errorPage'>
        <div className='cp-errorPage--inner'>
          <h1>Oops!</h1>
          <h2>{this.props.reason} :(</h2>
          <CtaBtn onClick={this.handleClick}>Return to the Login page</CtaBtn>
        </div>
      </div>
    );
  }
}
