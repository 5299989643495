import React from 'react';
import { Upload } from '../components/upload';
import { QUERY_STATE } from '../../constants';
import { callApi } from '../../utils/api';
import { UPLOAD_API } from '../constants';
import { WindowExtended } from '../../index';
import { getCustomerData } from '../../app';
import { ROLES } from '../../users/constants';

interface IBodyCallParams {
  data: string | ArrayBuffer | null | {}[];
  label: string;
  type: string;
  customer?: string;
}
export interface IUploadProps {
  name: string;
  formData: IUploadState;
  uiSchema: { title: string };
  onChange(state: IUploadState): void;
}

export interface IUploadState {
  uploadingQueryState: QUERY_STATE;
  uploadedFilesQueryState: QUERY_STATE;
  source: string;
  inputValue: string;
  uploadedFiles: { value: string; label: string }[];
}

interface IUploadedFilesItem {
  label: string;
  id: string;
}

export class UploadContainer extends React.PureComponent<IUploadProps, IUploadState> {
  public static count = 0;
  private api = '';
  private config: any = (window as WindowExtended & typeof globalThis).APP_CONFIG || {};
  private controller = new AbortController();
  private signal: AbortSignal;
  private isAborting = false;
  private customerData: ReturnType<typeof getCustomerData>;

  static defaultProps = {
    formData: {
      uploadingQueryState: QUERY_STATE.EMPTY,
      uploadedFilesQueryState: QUERY_STATE.EMPTY,
      uploadedFiles: [],
      source: '',
      inputValue: '',
    },
  };

  constructor(props: IUploadProps) {
    super(props);

    this.state = {
      uploadingQueryState: props.formData.uploadingQueryState || QUERY_STATE.EMPTY,
      uploadedFilesQueryState: props.formData.uploadedFilesQueryState || QUERY_STATE.EMPTY,
      uploadedFiles: props.formData.uploadedFiles || [],
      source: props.formData.source || '',
      inputValue: props.formData.inputValue || '',
    };

    this.customerData = getCustomerData();
    this.signal = this.controller.signal;
  }

  public componentDidMount = () => {
    this.api = this.config.api + (UPLOAD_API as { [key: string]: string })[this.props.name];
    this.fetchSourceList();
  };

  public componentWillUnmount = () => {
    this.isAborting = true; // after API refactoring and it`s error state - remove ?
    this.controller.abort();
  };

  private handleDragOver = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Why?
  };

  private handleDrop = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const files = e.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      this.loadFile(files[i]);
    }
  };

  /*
   * load Customer JSON data list
   */
  private fetchSourceList = async () => {
    this.setState({ uploadedFilesQueryState: QUERY_STATE.WAITING });
    const response = await callApi({
      api: this.api,
      path: this.customerData.auth.user.role === ROLES.SUPERADMIN ? '' : '?relevantOnly=true',
      accessToken: this.customerData.auth.access,
      refreshToken: this.customerData.auth.refresh,
      signal: this.signal,
      dispatch: this.customerData.dispatch,
    });
    if (response) {
      let uploadedFiles = response.results;
      if (this.props.name === 'uploadTableData') {
        uploadedFiles = uploadedFiles.filter(
          (item: IUploadedFilesItem) => `${this.api}${item.id}/` === this.state.source,
        );
      }
      uploadedFiles = uploadedFiles.map((item: IUploadedFilesItem) => {
        return { label: item.label, value: `${this.api}${item.id}/` };
      });

      this.setState(
        {
          uploadedFilesQueryState: QUERY_STATE.SUCCESS,
          uploadedFiles,
        },
        () => this.props.onChange(this.state),
      );
    } else {
      !this.isAborting &&
        this.setState({ uploadedFilesQueryState: QUERY_STATE.FAIL }, () =>
          this.props.onChange(this.state),
        );
    }
  };

  /*
   * load JSON/CVS files and convert into JS object
   * Endpoint pro nahrani csv
   */
  private loadFile = async (file: Blob) => {
      this.setState({ uploadingQueryState: QUERY_STATE.WAITING });

      const fileName = (file as any).name;
      const fileType = fileName.split('.').pop();

      let fileUploadResponse = undefined;
      switch(fileType) {
          case 'json': {
              const reader = new FileReader();
              reader.readAsText(file);
              reader.onload = async () => {
                  const data = reader.result;
                  let body: IBodyCallParams = {
                      data,
                      label: fileName,
                      type: fileType,
                  };
                  if (this.customerData.selectedCustomer) {
                      body.customer = this.customerData.selectedCustomer;
                  }

                  fileUploadResponse = await callApi({
                      api: this.api,
                      path: '',
                      method: 'POST',
                      body,
                      accessToken: this.customerData.auth.access,
                      refreshToken: this.customerData.auth.refresh,
                      signal: this.signal,
                      dispatch: this.customerData.dispatch,
                  });
                if (fileUploadResponse) {
                  this.onUploadSuccess(fileUploadResponse);
                } else {
                  this.setUploadQueryStateTimeout(QUERY_STATE.FAIL);
                }
              };
              break;
          };
          case 'csv': {
              const formData = new FormData();
              formData.append('file', file);
              formData.append('customer', this.customerData.selectedCustomer);

              fileUploadResponse = await callApi({
                  api: this.api,
                  path: '',
                  method: 'POST',
                  rawBody: formData,
                  accessToken: this.customerData.auth.access,
                  refreshToken: this.customerData.auth.refresh,
                  signal: this.signal,
                  dispatch: this.customerData.dispatch,
              });
              if (fileUploadResponse) {
                this.onUploadSuccess(fileUploadResponse);
              } else {
                this.setUploadQueryStateTimeout(QUERY_STATE.FAIL);
              }
            break;
          }
          default: {
            this.setUploadQueryStateTimeout(QUERY_STATE.FAIL);
            console.error(`Filetype "${fileType}" is not supported!`);
          }
      }
  };

  private onUploadSuccess = (fileUploadResponse: any) => {
    this.setUploadQueryStateTimeout(QUERY_STATE.SUCCESS);
    this.setState(
        {
            source: `${this.api}${fileUploadResponse.id}/`,
        },
        () => this.props.onChange(this.state),
    );
    this.fetchSourceList();
  }

  public handleSourceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        source: e.currentTarget.value,
      },
      () => this.props.onChange(this.state),
    );
  };

  public handleInputChange = (
    e: React.ChangeEvent | React.MouseEvent,
    eventData: { name: string; value: string },
  ) => {
    this.setState(
      {
        inputValue: eventData.value,
        source: eventData.value,
      },
      () => this.props.onChange(this.state),
    );
  };

  public onFileChange = (event: React.BaseSyntheticEvent) => {
    const files = event.target.files; // FileList object.
    for (let i = 0; i < files.length; i++) {
      this.loadFile(files[i]);
    }
  };

  private setUploadQueryStateTimeout = (timeoutState: QUERY_STATE) => {
    this.setState({ uploadingQueryState: timeoutState });
    window.setTimeout(
      () =>
        this.setState({ uploadingQueryState: QUERY_STATE.EMPTY }, () =>
          this.props.onChange(this.state),
        ),
      5000,
    );
  };

  public render() {
    const domName = this.props.name + ++UploadContainer.count; // unique ID for DOM
    return (
      <Upload
        name={domName}
        id={this.props.name}
        title={this.props.uiSchema.title}
        {...this.state}
        onFileChange={this.onFileChange}
        onInputClick={this.handleSourceChange}
        onInputChange={this.handleInputChange}
        onSourceChange={this.handleSourceChange}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
      />
    );
  }
}
