import React from 'react';
import { RouteComponentProps, NavLink } from 'react-router-dom';

import { CtaBtn } from '@tmapy/style-guide';

import { IMenuProps } from '../containers/menu';

import './menu.sass';

export class Menu extends React.PureComponent<IMenuProps & RouteComponentProps, {}> {
  private handleNewMapClick = () => {
    this.props.history.push('/maps/new');
  };
  private handleNewUserClick = () => {
    this.props.onNewUserClick();
  };

  public render() {
    return (
      <div className='sg-a-d-f sg-u-bb sg-a-mt-4'>
        <div className='sg-a-mr-2'>
          <nav className='cp-menu'>
            <ul className='sg-a-d-f'>
              <li>
                <NavLink activeClassName='cp-menuItem-isActive' exact to='/'>
                  Analytical Maps
                </NavLink>
              </li>
              {this.props.isLoggedAdmin && (
                <li>
                  <NavLink activeClassName='cp-menuItem-isActive' to='/users/'>
                    Users
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
        </div>
        <div className='sg-a-ml-a sg-a-d-f sg-a-ai-c sg-a-pb-1/2'>
          {this.props.isLoggedAdmin && (
            <>
              {this.props.history.location.pathname === '/users/' && (
                <CtaBtn onClick={this.handleNewUserClick}>Add New User</CtaBtn>
              )}
              {this.props.history.location.pathname === '/' && (
                <CtaBtn onClick={this.handleNewMapClick}>Create New Map</CtaBtn>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
