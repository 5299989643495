import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter } from 'react-router-dom';

import { LogoutBtn } from '../components/logoutbtn';
import { AppState } from '../../rootreducer';
import { logout } from '../actions';

export type ILogoutProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: AppState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onLogoutBtnClick: () => dispatch(logout()),
});

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;

export const LogoutContainer = withRouter(
  connect<IStateProps, IDispatchProps, {}, AppState>(
    mapStateToProps,
    mapDispatchToProps,
  )(LogoutBtn),
);
