import React from 'react';

import { BtnBar, Dialog, Popup, POPUP_POSITION, SecondaryBtn } from '@tmapy/style-guide';

import { UserAvatar, USER_AVATAR_THEME } from './useravatar';
import { LogoutContainer } from '../../auth/containers/logout';
import { IUserInfoProps } from '../containers/userinfo';
import { ProfileContainer } from '../../users/containers/profile';
import { UI } from '../../constants';

import './userinfo.sass';

interface IUserInfoState {
  isPopupShown: boolean;
}

export class UserInfo extends React.PureComponent<IUserInfoProps, IUserInfoState> {
  private popupContainerRef: React.RefObject<HTMLButtonElement> = React.createRef();
  private popupPosition = [POPUP_POSITION.BOTTOM_CENTER, POPUP_POSITION.BOTTOM_LEFT];

  public readonly state: IUserInfoState = {
    isPopupShown: false,
  };

  private handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({
      isPopupShown: !this.state.isPopupShown,
    });
  };

  private handleOutsidePopupClick = () => {
    this.setState({
      isPopupShown: false,
    });
  };

  public render() {
    return (
      <>
        <button
          type='button'
          className='cp-userInfo'
          ref={this.popupContainerRef}
          onClick={this.handleClick}
        >
          <UserAvatar theme={USER_AVATAR_THEME.DARK}>
            {`${this.props.firstname[0]}${this.props.lastname[0]}`}
          </UserAvatar>
          {this.state.isPopupShown && (
            <Popup
              position={this.popupPosition}
              containerRef={this.popupContainerRef}
              onOutsideClick={this.handleOutsidePopupClick}
            >
              <div className='sg-a-p-2 sg-u-vs-2 sg-a-d-f sg-a-fd-c sg-a-ai-fs'>
                <figure className='sg-a-d-f'>
                  <UserAvatar theme={USER_AVATAR_THEME.LIGHT}>
                    {`${this.props.firstname[0]}${this.props.lastname[0]}`}
                  </UserAvatar>
                  <figcaption className='sg-a-ml-1 sg-u-type-sml'>
                    <div>
                      {this.props.firstname} {this.props.lastname}
                    </div>
                    <div className='sg-u-type-xsm sg-u-type-italic sg-u-c-body-darkest'>
                      {this.props.email}
                    </div>
                  </figcaption>
                </figure>
                <BtnBar>
                  <SecondaryBtn onClick={this.props.onProfileClick}>Profile</SecondaryBtn>
                  <LogoutContainer />
                </BtnBar>
              </div>
            </Popup>
          )}
        </button>
        {this.props.isProfileVisible && (
          <Dialog width={UI.USER_DIALOG_WIDTH} onEscKeyDown={this.props.onHide}>
            <ProfileContainer onCloseDialogBtn={this.props.onHide} />
          </Dialog>
        )}
      </>
    );
  }
}
