import React from 'react';
import { Redirect } from 'react-router';

import { callApi } from '../../utils/api';
import { QUERY_STATE } from '../../constants';
import { schema } from '../config/schema';
import { bookmarks } from '../config/bookmarks';
import { locale } from '../config/locale';

import { EDITOR_TYPE } from '../constants';
import { Editor } from '../components/editor';
import { IEditMapProps } from '../../maps/containers/editmap';
import { INewMapProps } from '../../maps/containers/newmap';
import { baseMap } from '../config/basemapbasic';
import { Jedex } from '@tmapy/jedex';
import { UploadContainer } from '../containers/upload';
import { INPUT_MODE } from '@tmapy/jedex/es/constants';

import { loadConfigData, getIdOfSources, getConfigurationWithSaveUrl } from '../utils/data';

interface IBodyCallParams {
  name: string;
  configuration: string;
  allowedUsers: number[];
  isVisibilityPublic: boolean;
  data?: string;
  resources?: string[];
  areas?: string[];
  geoenrichments?: string[];
  customer?: string;
}

export default interface IEditorState {
  postMessageSource: Window | undefined;
  isCorpisReady: boolean;
  saveQueryState: QUERY_STATE;
  queryState: QUERY_STATE;
  jedexConfig: {
    jsonFile: any; // just init json data for JEDEX!
    bookmarks: typeof bookmarks;
    schema: typeof schema;
    locale: typeof locale;
  };
  isMapPublic: boolean;
  isPermissionDialogShown: boolean;
  allowedUsers: number[];
  needAuthentication: boolean;
  showRefreshBtn: boolean;
  showJedex: boolean;
  jedexModes: { value: string; label: string }[];
  activeMode: INPUT_MODE;
}

export class EditorContainer extends React.PureComponent<
  IEditMapProps | INewMapProps,
  IEditorState
> {
  private jedexRef: React.RefObject<Jedex> = React.createRef();
  private fields = { upload: UploadContainer };

  constructor(props: IEditMapProps) {
    super(props);

    this.state = {
      postMessageSource: undefined,
      isCorpisReady: false,
      saveQueryState: QUERY_STATE.EMPTY,
      queryState: QUERY_STATE.EMPTY,
      jedexConfig: {
        jsonFile: undefined,
        bookmarks,
        schema,
        locale,
      },
      isMapPublic: false,
      isPermissionDialogShown: false,
      allowedUsers: [],
      needAuthentication: false,
      showRefreshBtn: false,
      showJedex: true,
      jedexModes: [
        {
          value: INPUT_MODE.FORM,
          label: 'Form',
        },
        {
          value: INPUT_MODE.CODE,
          label: 'Code',
        },
      ],
      activeMode: INPUT_MODE.FORM,
    };

    window.addEventListener('message', this.receiveMessage);
  }

  public componentWillUnmount() {
    window.removeEventListener('message', this.receiveMessage);
  }

  public async componentDidMount() {
    this.setState({ queryState: QUERY_STATE.WAITING });

    if (this.props.type === EDITOR_TYPE.NEW) {
      this.setState((prevState) => ({
        jedexConfig: {
          ...prevState.jedexConfig,
          jsonFile: baseMap,
        },
      }));

      this.state.isCorpisReady && this.sendConfig(baseMap);
    }

    if (this.props.type === EDITOR_TYPE.EDIT) {
      /*
            - ocekava odezvu endpointu s:
                - validnim configuration
                - informaci ze je potreba se prihlasit (pokud jiz neni)
                - informaci ze na mapu prihlaseny uzivatel nema pravo
            */
      const response = await callApi({
        api: this.props.api,
        path: this.props.path,
        accessToken: this.props.accessToken,
        refreshToken: this.props.refreshToken,
        dispatch: this.props.dispatch,
      });
      // zobrazeni map klienta
      if (response && response.configuration) {
        this.setState((prevState) => ({
          allowedUsers: response.allowedUsers,
          isMapPublic: response.isVisibilityPublic,
          jedexConfig: {
            ...prevState.jedexConfig,
            jsonFile: response.configuration,
          },
        }));

        this.state.isCorpisReady && this.sendConfig(response.configuration);
      } else {
        // zobrazeni chybove hlasky v pripade prihlaseni, ale neopravneni, nebo neexistujici mapy
        if (this.props.isAuthenticated) {
          this.setState({ queryState: QUERY_STATE.FAIL });
        }
        // presmerovani na login page
        else {
          this.setState({
            needAuthentication: true,
          });
        }
      }
    }
  }

  /**
   * Listener for Window.postMessage()
   * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
   * @param e event
   */
  private receiveMessage = (e: any) => {
    if (e.data && e.data.corpis && e.data.corpis.ready) {
      this.setState({
        isCorpisReady: true,
        postMessageSource: e.source,
      });

      const jsonFile = this.state.jedexConfig.jsonFile;
      if (jsonFile && Object.keys(jsonFile).length > 0) {
        this.sendConfig(jsonFile);
      }
    }
  };

  private handleSaveBtnClick = async () => {
    this.setState({ saveQueryState: QUERY_STATE.WAITING });

    const config = this.jedexRef.current ? this.jedexRef.current.getJsonData() : {};

    let body: IBodyCallParams = {
      name: config.brand.appName.title || 'Map',
      configuration: config,
      // urls pro uploadovane datove zdroje, aby se to na backendu mohlo propojit
      ...getIdOfSources(config.data),
      allowedUsers: this.state.allowedUsers,
      isVisibilityPublic: this.state.isMapPublic,
    };
    if (this.props.type === EDITOR_TYPE.NEW && this.props.selectedCustomer) {
      body.customer = this.props.selectedCustomer;
    }

    const method = this.props.type === EDITOR_TYPE.EDIT ? 'PATCH' : 'POST';
    const response = await callApi({
      api: this.props.api,
      path: this.props.path,
      accessToken: this.props.accessToken,
      refreshToken: this.props.refreshToken,
      method,
      body,
      dispatch: this.props.dispatch,
    });

    // TODO: null > ERROR! > some DANGER Alert!
    this.setState({
      saveQueryState: response ? QUERY_STATE.SUCCESS : QUERY_STATE.FAIL,
    });

    // OK > change route
    response && this.props.history.push('/');
  };

  private handleCancelBtnClick = () => {
    this.props.history.push('/');
  };

  /**
   * Send configuration to Corpis Maps over postMessage
   * https://codemirror.net/doc/manual.html#addons
   * inspiration https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
   */
  private async sendConfig(config: any) {
    const fullClientConfiguration: any = await loadConfigData(
      config,
      this.props.accessToken,
      this.props.api,
    );

    if (fullClientConfiguration) {
      const dataUrl = config?.data?.table.uploadTableData.source;
      const configurationWithSaveUrl = dataUrl
        ? getConfigurationWithSaveUrl(fullClientConfiguration, dataUrl)
        : fullClientConfiguration;

      this.setState({ queryState: QUERY_STATE.SUCCESS });

      const targetWin = this.state.postMessageSource || (window as any)['corpisClient'];
      targetWin.postMessage(
        {
          corpis: {
            config: configurationWithSaveUrl,
            refreshToken: this.props.refreshToken,
            api: this.props.api,
          },
        },
        '*',
      );
    }
  }

  private handlePublicMapFlagChange = () => {
    this.setState((prevState) => ({
      isMapPublic: !prevState.isMapPublic,
    }));
  };

  private handleShareBtnClick = () => {
    this.setState((prevState) => ({
      isPermissionDialogShown: !prevState.isPermissionDialogShown,
    }));
  };

  private handlePermissionSubmit = (allowedUsers: number[]) => {
    this.setState((prevState) => ({
      isPermissionDialogShown: !prevState.isPermissionDialogShown,
      allowedUsers,
    }));
  };

  private handleRefreshBtnClick = () => {
    if (this.jedexRef.current) {
      this.setState({
        showRefreshBtn: false,
        queryState: QUERY_STATE.WAITING,
      });
      this.sendConfig(this.jedexRef.current.getJsonData());
    }
  };

  private handleJedexChange = () => {
    this.setState({
      showRefreshBtn: true,
    });
  };

  private handleFormSwitcherClick = () => {
    this.setState((prevState) => ({
      showJedex: !prevState.showJedex,
    }));
  };

  private handleModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const jsonFile = this.jedexRef.current ? this.jedexRef.current.getJsonData() : {};
    this.setState({
      activeMode: e.target.value as INPUT_MODE,
      jedexConfig: {
        ...this.state.jedexConfig,
        jsonFile,
      },
    });
  };

  public render() {
    return this.state.needAuthentication ? (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: this.props.location },
        }}
      />
    ) : (
      <Editor
        {...this.state}
        {...this.props}
        jedexRef={this.jedexRef}
        fields={this.fields}
        onJedexChange={this.handleJedexChange}
        onPublicMapFlagChange={this.handlePublicMapFlagChange}
        onPermissionSubmit={this.handlePermissionSubmit}
        onFormSwitcherClick={this.handleFormSwitcherClick}
        onShareBtnClick={this.handleShareBtnClick}
        onRefreshBtnClick={this.handleRefreshBtnClick}
        onCancelBtnClick={this.handleCancelBtnClick}
        onSaveBtnClick={this.handleSaveBtnClick}
        onModeChange={this.handleModeChange}
      />
    );
  }
}
