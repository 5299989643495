import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { UserFormContainer } from './userform';

import { USER_FORM_TITLE, USER_FORM_TYPE } from '../constants';
import { AppState } from '../../rootreducer';
import { logout } from '../../auth/actions';
import { hideProfile } from '../../app/actions';

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;

export type IEditUserProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: AppState) => ({
  api: state.app.config.api,
  path: `/users/${state.auth.user.id}/`,
  accessToken: state.auth.access,
  refreshToken: state.auth.refresh,
  token: state.auth.access,
  formType: USER_FORM_TYPE.PROFILE,
  formTitle: USER_FORM_TITLE.PROFILE,
  failText: 'Editing of the profile is not currently available. Please try again later.',
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onSuccessSubmit: () => {
    dispatch(hideProfile());
    dispatch(logout());
  },
  onClose: () => {
    dispatch(hideProfile());
  },
  dispatch,
});

export const ProfileContainer = connect<IStateProps, IDispatchProps, any, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(UserFormContainer);
