export const storage = {
  saveData(item: string, data: any) {
    try {
      localStorage.setItem(item, JSON.stringify(data));
    } catch (err) {
      console.error(`ERROR: Persisting the ${item} to the Local Storage is not supported.`);
    }
  },

  getData(item: string): any {
    try {
      const serializedState = localStorage.getItem(item);
      return serializedState ? JSON.parse(serializedState) : {};
    } catch (err) {
      return {};
    }
  },

  clear() {
    try {
      localStorage.clear();
    } catch (err) {
      console.error(`ERROR: Clear Local Storage is not supported.`);
    }
  },

  removeData(item: string) {
    try {
      localStorage.removeItem(item);
    } catch (err) {
      console.error(`ERROR: Remove item: ${item} from Local Storage is not supported.`);
    }
  },
};
