import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter } from 'react-router-dom';

import { MapList } from '../components/maplist';
import { AppState } from '../../rootreducer';
import { getMaps, setDeleteQueryState } from '../actions';
import { DELETE_MAP_STATE } from '../constants';
import { QUERY_STATE } from '../../constants';

export type IMapListProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: AppState) => ({
  maps: state.maps.list,
  queryState: state.maps.queryState,
  deleteQueryState: state.maps.deleteQueryState,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onComponentMount: () => dispatch(getMaps()),
  onComponentUnmount: () =>
    dispatch(setDeleteQueryState(DELETE_MAP_STATE.EMPTY, QUERY_STATE.EMPTY)),
});

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;

export const MapListContainer = withRouter(
  connect<IStateProps, IDispatchProps, {}, AppState>(mapStateToProps, mapDispatchToProps)(MapList),
);
