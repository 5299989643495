import { createSelector } from 'reselect';
import { AppState } from '../../rootreducer';
import { ICustomer } from '../../auth/actions';

export const getCustomerLabel = createSelector(
  [
    (state: AppState) => state.auth.user.customersList,
    (state: AppState) => state.auth.selectedCustomer,
  ],
  (customers: ICustomer[], selectedCustomer) => {
    const customer = customers.find((item) => item.value === +selectedCustomer);
    return customer ? customer.label : '';
  },
);
