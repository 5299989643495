import React, { useCallback } from 'react';

import {
  DangerAlert,
  Input,
  Radio,
  SecondaryBtn,
  SuccessAlert,
  WaitingAlert,
} from '@tmapy/style-guide';

import { IUploadState } from '../containers/upload';

import { QUERY_STATE } from '../../constants';

import './upload.sass';

interface IUploadProps {
  name: string;
  id: string;
  title: string;
  onFileChange(e: React.BaseSyntheticEvent): void;
  onInputChange(
    e: React.ChangeEvent | React.MouseEvent,
    eventData: { name: string; value: string },
  ): void;
  onInputClick(e: React.MouseEvent | React.ChangeEvent, eventData: { name?: string }): void;
  onSourceChange(e: React.ChangeEvent): void;
  onDragOver(e: React.DragEvent): void;
  onDrop(e: React.DragEvent): void;
}
export const Upload: React.FC<IUploadProps & IUploadState> = (props) => {
  const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
  const isAreasUpload = props.id === 'uploadAreasData';
  const acceptFileType = isAreasUpload ? '.json' : '.csv';
  const description = isAreasUpload
    ? 'Supported file formats: Upload (JSON), URL (JSON).'
    : 'Supported file formats: Upload (CSV), URL (CSV and JSON).';

  const handleChooseFileBtnClick = useCallback(() => {
    fileInputRef.current && fileInputRef.current.click();
  }, [fileInputRef]);

  return (
    <>
      <input
        ref={fileInputRef}
        type='file'
        style={{ display: 'none' }}
        accept={acceptFileType}
        onChange={props.onFileChange}
      />
      <div className='sg-u-vs-2'>
        {props.title && <legend>{props.title}</legend>}
        <p>{description}</p>
        <div className='cp-dropZone sg-u-vs-1' onDragOver={props.onDragOver} onDrop={props.onDrop}>
          {props.uploadingQueryState === QUERY_STATE.EMPTY && (
            <>
              <div>Drop new data file here or</div>
              <SecondaryBtn onClick={handleChooseFileBtnClick}>Choose file</SecondaryBtn>
            </>
          )}
          {props.uploadingQueryState === QUERY_STATE.WAITING && (
            <WaitingAlert>Uploading...</WaitingAlert>
          )}
          {props.uploadingQueryState === QUERY_STATE.FAIL && (
            <DangerAlert>There are some problems during upload process!</DangerAlert>
          )}
          {props.uploadingQueryState === QUERY_STATE.SUCCESS && (
            <SuccessAlert>File uploaded successfully.</SuccessAlert>
          )}
        </div>
        {props.uploadedFilesQueryState === QUERY_STATE.WAITING && (
          <WaitingAlert>Loading data files...</WaitingAlert>
        )}
        {props.uploadedFilesQueryState === QUERY_STATE.FAIL && (
          <DangerAlert>The file wasn't uploaded. Please try again later.</DangerAlert>
        )}
        <div>
          {props.uploadedFilesQueryState === QUERY_STATE.SUCCESS &&
            props.uploadedFiles.map((item) => (
              <Radio
                key={item.value}
                name={props.name}
                value={item.value}
                isChecked={props.source === item.value}
                onChange={props.onSourceChange}
              >
                {item.label}
              </Radio>
            ))}
          <Radio
            name={props.name}
            value={props.inputValue}
            isChecked={props.source === props.inputValue}
            onChange={props.onSourceChange}
          >
            <div className='sg-a-d-f sg-a-fd-c'>
              <Input
                placeholder='Enter the file URL address'
                value={props.inputValue}
                onChange={props.onInputChange}
                onClick={props.onInputClick}
              />
            </div>
          </Radio>
        </div>
      </div>
    </>
  );
};
