import { Dispatch } from 'redux';

import { AUTH_USER, LOGOUT_USER, SET_SELECTED_CUSTOMER } from './constants';
import { GetState } from '../types';

export interface ICustomer {
  label: string;
  value: number;
}

export interface IAuthResponse {
  refresh: string;
  access: string;
  user: {
    id: number;
    name: string;
    surname: string;
    role: number;
    customersList: ICustomer[];
  };
}

export const authUser =
  (email: string, password: string) => (dispatch: Dispatch, getState: GetState) => {
    const api = getState().app.config.api;

    dispatch({
      type: `GET_${AUTH_USER}_WAITING`,
    });

    fetch(`${api}/auth/jwt/create`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response: Response) => {
        if (response.status >= 400) {
          dispatch({
            type: `GET_${AUTH_USER}_FAIL`,
            error: response.status,
          });
        } else {
          const result: IAuthResponse = await response.json();
          result.user.customersList &&
            dispatch(setCustomer(`${result.user.customersList[0].value}`));
          dispatch({
            type: `GET_${AUTH_USER}_SUCCESS`,
            data: {
              ...result,
              isAuthenticated: true,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: `GET_${AUTH_USER}_FAIL`,
          error: err,
        });
      });
  };

export const logout = () => ({
  type: LOGOUT_USER,
});

export const setCustomer = (id = '') => ({
  type: SET_SELECTED_CUSTOMER as typeof SET_SELECTED_CUSTOMER,
  id,
});
