import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { AppState } from './rootreducer';
import { ROLES } from './users/constants';
import { ErrorPage } from './app/components/errorpage';

export class PrivateRoute extends React.PureComponent<RouteProps & IPrivateRouteProps, {}> {
  private handleRender = (props: any) => {
    const { component: Component, isAuthenticated, loggedRole } = this.props;

    if (!Component) return;

    // Permissions
    if (isAuthenticated && loggedRole === ROLES.VIEWER) {
      if (
        props.match.path.indexOf('/users') !== -1 ||
        props.match.path.indexOf('/maps/edit') !== -1
      ) {
        return <ErrorPage reason='Access denied' history={props.history} />;
      }
    }

    return isAuthenticated ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location },
        }}
      />
    );
  };

  public render() {
    // tslint:disable-next-line:no-unused
    const { component, ...rest } = this.props;

    return <Route {...rest} render={this.handleRender} />;
  }
}

const mapStateToProps = (state: AppState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loggedRole: state.auth.user.role,
});

type IPrivateRouteProps = ReturnType<typeof mapStateToProps>;

export const PrivateRouteContainer = connect(mapStateToProps)(PrivateRoute);
