import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { UserInfo } from '../components/userinfo';
import { AppState } from '../../rootreducer';
import { showProfile, hideProfile } from '../actions';

export type IUserInfoProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: AppState) => ({
  firstname: state.auth.user.name,
  lastname: state.auth.user.surname,
  email: state.auth.user.email,
  isProfileVisible: state.app.profile.isVisible,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  onProfileClick: () => {
    dispatch(showProfile());
  },
  onHide: () => {
    dispatch(hideProfile());
  },
});

type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IStateProps = ReturnType<typeof mapStateToProps>;

export const UserInfoContainer = connect<IStateProps, IDispatchProps, {}, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(UserInfo);
