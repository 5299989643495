import { USER_FORM_TYPE } from '../constants';
import { validateEmail } from '@tmapy/webhk-js-lib';
import { IUserData, IUserDataKey } from './data';

export const getItemValidity = (ident: string, value: string | number) => {
  switch (ident) {
    case 'name':
    case 'surname':
    case 'password':
    case 'newPassword':
      return !!value;
    case 'email':
      return validateEmail(`${value}`);
    default:
      return true;
  }
};

export const getItemsForValidate = (form: USER_FORM_TYPE) => {
  let fields = ['name', 'surname', 'email'];
  form === USER_FORM_TYPE.NEW && (fields = [...fields, ...['password', 'newPassword']]);

  return fields;
};

export const getValidation = (data: IUserData, formType: USER_FORM_TYPE) => {
  let isFormValid = true;
  const validatedFields: { [key: string]: { value: string | number; isValid: boolean } } = {};

  getItemsForValidate(formType).forEach((ident) => {
    const value = data[ident as IUserDataKey].value;
    const isValid = getItemValidity(ident, value);

    // reset only when invalid
    !isValid && (isFormValid = false);

    validatedFields[ident] = {
      value,
      isValid,
    };
  });

  // password === newPassword item validation
  if (formType === USER_FORM_TYPE.NEW || formType === USER_FORM_TYPE.PROFILE) {
    if (data['password'].value !== data['newPassword'].value) {
      isFormValid && (isFormValid = false);
      validatedFields['newPassword'] = {
        value: data['newPassword'].value,
        isValid: false,
      };
    }
    if (data['password'].value.length < 8) {
      isFormValid && (isFormValid = false);
      validatedFields['password'] = {
        value: data['password'].value,
        isValid: false,
      };
    }
  }

  return { validatedFields, isFormValid };
};
