import { QUERY_STATE } from '../constants';
import * as con from './constants';
import { UserActionTypes } from './actions';
import { prepareFText } from '@tmapy/webhk-js-lib';
import { IUserDataList } from './interfaces';

const defaultState = {
  usersList: [] as IUserDataList[],
  queryState: QUERY_STATE.WAITING,
  deleteQueryState: QUERY_STATE.EMPTY,
  filterName: '',
  editId: NaN,
  formType: con.USER_FORM_TYPE.NONE,
  isFormVisible: false,
};

export const userlist = (state = defaultState, action: UserActionTypes): typeof defaultState => {
  switch (action.type) {
    case con.USERS_LOAD_FAILURE:
      return {
        ...state,
        ...{
          usersList: [] as IUserDataList[],
          queryState: QUERY_STATE.FAIL,
        },
      };
    case con.USERS_LOAD_SUCCESS:
      return {
        ...state,
        ...{
          usersList: prepareData(action.data),
          queryState: QUERY_STATE.SUCCESS,
        },
      };
    case con.USERS_FILTER_NAME_CHANGED:
      return {
        ...state,
        ...{
          filterName: action.value,
        },
      };
    case con.USERS_EDIT_DATA_CHANGED:
      return {
        ...state,
        ...{
          editId: action.id,
          formType: action.formType,
          isFormVisible: action.formType !== con.USER_FORM_TYPE.NONE,
        },
      };
    case con.DELETE_USER_STATE.FAILURE:
    case con.DELETE_USER_STATE.EMPTY:
    case con.DELETE_USER_STATE.SUCCESS:
      return {
        ...state,
        ...{
          deleteQueryState: action.queryState,
        },
      };
    case con.DELETE_USER:
      return {
        ...state,
        usersList: state.usersList.filter((item) => item.id !== action.id),
      };
    default:
      return state;
  }
};

const prepareData = (data: IUserDataList[]) => {
  data.forEach((item) => {
    item['fullText'] = `|${prepareFText(item.name)}|${prepareFText(item.surname)}|${prepareFText(
      item.email,
    )}`;
  });
  return data;
};
